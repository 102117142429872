import React, {useEffect, useReducer} from "react";
import PlanHeader from "./PlanHeader";
import PlanMenu from "./PlanMenu";
import PlanFooter from "./PlanFooter";
import {useBreadcrumbs} from "../../Utils";
import CreatePlan from "./Extensions/CreatePlan";

const PlanCenter: React.FC = () =>{

    useEffect(()=>{
        document.title = "BuildserSE Plan Manager"
    }, [])

    const initialState = {
        step: 0,
        subStep: 0,
        cardStep: 0,
        heading: "BuilderSE Plans Manager",
        subHeading: "Manage all your houseplans from this point",
        showHeading: true
    }

    const reducer = (state: any, action: any) => {

        switch (action.type){

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            case "SET_CARD_STEP":
                return {...state, cardStep: action.payload}

            case "SET_HEADING":
                return {...state, heading: action.payload}

            case "SET_SUB_HEADING":
                return {...state, subHeading: action.payload}

            case "SET_SHOW_HEADING":
                return {...state, showHeading: action.payload}

            default:
                return state
        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return(
        <>
            <PlanHeader />
            <PlanMenu />

            <div className={"main-content"}>

                <div className={"page-content"}>

                    {useBreadcrumbs("BSE Plans manager", "Dashboard", "Plan Manager Center")}

                    {state.showHeading && (
                        <>
                            <div className={"row mt-4"}>
                                <div className={"col text-center"}>

                                    <h3>{state.heading}</h3>

                                </div>
                            </div>

                            <div className={"row mt-3"}>
                                <div className={"col text-center"}>
                                    <h6>{state.subHeading}</h6>
                                </div>
                            </div>
                        </>
                    )}

                    {state.step === 0 && (
                        <>

                            <div className={"row mt-4"}>

                                <div className={"col-md-2"}>

                                    <div className={"card card-animate"} onClick={(e)=>{
                                        dispatch({type: "SET_SUB_HEADING", payload: "Create a new house plan"})
                                        dispatch({type: "SET_STEP", payload: 1})
                                    }}>

                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/create.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">New Plan</h5>
                                        </div>

                                    </div>

                                </div>

                                <div className={"col-md-2"}>

                                    <div className={"card card-animate"} onClick={(e)=>{
                                        dispatch({type: "SET_STEP", payload: 2})
                                    }}>

                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/list.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">My Plans</h5>
                                        </div>

                                    </div>

                                </div>

                                <div className={"col-md-2"}>

                                    <div className={"card card-animate"} onClick={(e)=>{
                                        dispatch({type: "SET_STEP", payload: 3})
                                    }}>

                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/bsecoin.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Billing</h5>
                                        </div>

                                    </div>

                                </div>

                                <div className={"col-md-2"}>

                                    <div className={"card card-animate"} onClick={(e)=>{
                                        dispatch({type: "SET_STEP", payload: 3})
                                    }}>

                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/help.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Complaints</h5>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </>
                    )}

                    {state.step === 1 && (
                        <>
                            <CreatePlan />
                        </>
                    )}


                </div>

                <PlanFooter />
            </div>

        </>
    )
}

export default PlanCenter