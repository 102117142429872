import React from 'react';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import ArtisanHome from "./artisans/home";
import ArtisanLogin from "./artisans/login";
import RegisterArtisans from "./artisans/Register";
import ProSearch from "./pros/search";
import RegisterPros from "./pros/Register";
import ProsHome from "./pros/home";
import ProsLogin from "./pros/login";
import ProJobs from "./pros/jobs";
import ArtisanSearch from "./artisans/search";
import ArtisanJobs from "./artisans/jobs";
import FileDownloader from "./Ftp";
import MaterialStart from "./material/start";
import MaterialHome from "./material/home";
import MaterialLogin from "./material/login";
import MaterialRegister from "./material/register";
import MaterialSearch from "./material/search";
import MaterialRequests from "./material/requests";
import HomePage from "./home/Home";
import ConcreteCalculators from "./calculators/Concrete";
import ArtisanDashboard from "./artisans/dashboard/ArtisanDashboard";
import ArtisanCatalog from "./artisans/dashboard/ArtisanCatalog";
import ArtisanProjects from "./artisans/dashboard/ArtisanProjects";
import HousePlansHome from "./houseplans/home";
import PlanSearchResults from "./houseplans/Results";
import OneLogin from "./home/OneLogin";
import Teams from "./teams/Teams";
import JoinTeam from "./teams/Join";
import ArtisanTeams from "./artisans/dashboard/ArtisanTeams";
import ArtisanMessaging from "./artisans/dashboard/ArtisanMessaging";
import ArtisanProfile from "./artisans/dashboard/ArtisanProfile";
import ProfessionalDashboard from "./pros/dashboard/ProfessionalDashboard";
import ProCatalog from "./pros/dashboard/ProCatalog";
import ProMessaging from "./pros/dashboard/ProMessaging";
import ProProfile from "./pros/dashboard/ProProfile";
import ProProjects from "./pros/dashboard/ProProjects";
import ProTeams from "./pros/dashboard/ProTeams";
import PlanFooter from "./houseplans/dashboard/PlanFooter";
import PlanDashboard from "./houseplans/dashboard/PlanDashboard";
import PlanHelpSupport from "./houseplans/dashboard/PlanHelpSupport";
import PlanProfile from "./houseplans/dashboard/PlanProfile";
import PlanCenter from "./houseplans/dashboard/PlanCenter";
import PlanProjects from "./houseplans/dashboard/PlanProjects";

function App() {
  return (
      <>
        <Router>
          <Routes>

            <Route path={"/"} element={<HomePage />} />
            <Route path={"/onelogin"} element={<OneLogin />} />

            {/*Teams*/}
            <Route path={"/teams"} element={<Teams />} />
            <Route path={"/teams/join"} element={<JoinTeam />} />

            {/*Calculators*/}
            <Route path={"/calculators"} element={<ConcreteCalculators />} />

            {/*Artisans*/}
            <Route path={"/artisans"} element={<ArtisanHome />} />
            <Route path={"/artisans/login"} element={<ArtisanLogin />} />
            <Route path={"/artisans/register"} element={<RegisterArtisans />} />
            <Route path={"/artisans/search"} element={<ArtisanSearch />} />
            <Route path={"/artisans/jobs"} element={<ArtisanJobs />} />

              {/*Dashboards - Artisan*/}
              <Route path={"/artisans/dashboard/"} element={<ArtisanDashboard />} />
              <Route path={"/artisans/dashboard/catalog"} element={<ArtisanCatalog />} />
              <Route path={"/artisans/dashboard/projects"} element={<ArtisanProjects />} />
              <Route path={"/artisans/dashboard/teams"} element={<ArtisanTeams />} />
              <Route path={"/artisans/dashboard/messaging"} element={<ArtisanMessaging />} />
              <Route path={"/artisans/dashboard/profile"} element={<ArtisanProfile />} />


            {/*Pros*/}
            <Route path={"/pros"} element={<ProsHome />} />
            <Route path={"/pros/login"} element={<ProsLogin />} />
            <Route path={"/pros/register"} element={<RegisterPros />} />
            <Route path={"/pros/search"}  element={<ProSearch />} />
            <Route path={"/pros/jobs"} element={<ProJobs />} />


            <Route path={"/pros/dashboard"} element={<ProfessionalDashboard />} />
            <Route path={"/pros/dashboard/catalog"} element={<ProCatalog />} />
            <Route path={"/pros/dashboard/messaging"} element={<ProMessaging />} />
            <Route path={"/pros/dashboard/profile"} element={<ProProfile />} />
            <Route path={"/pros/dashboard/projects"} element={<ProProjects />} />
            <Route path={"/pros/dashboard/teams"} element={<ProTeams />} />


            <Route path={"ftp"} element={<FileDownloader />} />

            {/*Material*/}
            <Route path={"/material"} element={<MaterialStart />} />
            <Route path={"/material/home/:category"} element={<MaterialHome />} />
            <Route path={"/material/search"} element={<MaterialSearch />} />
            <Route path={"/material/login"} element={<MaterialLogin />} />
            <Route path={"/material/register"} element={<MaterialRegister />} />
            <Route path={"/material/requests"} element={<MaterialRequests />} />

            {/*Vendors*/}

            {/*HousePlans*/}
            <Route path={"/houseplans"} element={<HousePlansHome />} />
            <Route path={"/houseplans/details"} element={<PlanSearchResults />} />

            {/*Houseplans Dashboard*/}
            <Route path={"/houseplans/dashboard"} element={<PlanDashboard />} />
            <Route path={"/houseplans/dashboard/projects"} element={<PlanProjects />} />
            <Route path={"/houseplans/dashboard/myplans"} element={<PlanCenter />} />
            <Route path={"/houseplans/dashboard/help"} element={<PlanHelpSupport />} />
            <Route path={"/houseplans/dashboard/profile"} element={<PlanProfile />} />


          </Routes>
        </Router>
      </>
  );
}

export default App;
