import React, {useState} from 'react'
import {useAppName, useBaseUrl, usePostData} from "../Utils";
import {Simulate} from "react-dom/test-utils";
import cut = Simulate.cut;

const ConcreteCalculators: React.FC = () => {
    const [step, setStep] = useState<any>(2)
    const [shape, setShape] = useState<any>()
    const [height, setHeight] = useState<any>()
    const [length, setLength] = useState<any>()
    const [width, setWidth] = useState<any>()
    const [cement, setCement] = useState<any>()
    const [shrinkage, setShrinkage] = useState<any>()
    const [cemClass, setCemClass] = useState<any>(15)
    const [spacing, setSpacing] = useState<any>(0)
    const [widthSpacing, setWidthSpacing] = useState<any>()
    const [lengthSpacing, setLengthSpacing] = useState<any>()
    const [inUse, setInUse] = useState<boolean>(false)
    const [type, setType] = useState("colPad")
    const [disab, setDisab] = useState<boolean>(false)
    const [barType, setBarType] = useState("")
    const [piecesWidth, setPiecesWidth] = useState<any>()
    const [fullBarLen, setFullBarLen] = useState<number>(12)
    const [b1Len, setB1Len] = useState<any>()
    const [b2Len, setB2Len] = useState<any>()
    const [pads, setPads] = useState<number>(1)
    const [cols, setCols] = useState<any>(1)
    const [overlap, setOverlap] = useState<any>()
    const [beamPieces, setBeamPieces] = useState<any>()
    const [ringSpacing, setRingSpacing] = useState<any>()
    const [ringBarType, setRingBarType] = useState<any>()


    const [volume, setVolume] = useState<any>()
    const [pieces, setPieces] = useState<any>()

    const [results, setResults] = useState<any>([])

    const CalculateVolume = () => {

        const vol = length * width * height

        setVolume(vol)

    }

    const PiecesReqd = (spacing: any) =>{

        let pcs: any = width/spacing + 1

        return pcs
    }

    const CalculatePiecesWidth = () => {

        let pcs = length/widthSpacing + 1

        let wid_pcs = (pcs * width) / 12

        let cutWid = length + (1.5 * height)

        setB2Len(cutWid)

        setPiecesWidth(wid_pcs)

    }

    const CalculatePieces = () => {

        let pcs: any = PiecesReqd(lengthSpacing);

        let len_pcs = (pcs * width) / 12;

        let cutLen = width + (1.5 * height)

        setB1Len(cutLen)

        setPieces(len_pcs)

    }

    const CalculateStubPieces = async () => {

        let url = `${useBaseUrl()}/calculators/stubs`

        const data = new FormData()

        data.append("length", length)
        data.append("width", width)
        data.append("height", height)
        data.append("overlap", overlap)
        data.append("columns", cols)
        data.append("pieces", beamPieces)
        data.append("ring_spacing", ringSpacing)

        const resp = await usePostData(url, data)

        setResults(resp)

    }

    return (
        <>
            {step===1 && (
                <section>

                    <div className={"row mt-3"}>
                        <div className={"col-md-2"}>

                        </div>

                        <div className={"col-md-8"}>

                            <div className={"card"}>
                                <div className={"card-header"}>
                                    Mixed Ratio Calculator
                                </div>
                                <div className={"card-body"}>
                                    <div className={"row mt-4"}>
                                        <div className={"col"}>

                                            <select className={"form-control"} onChange={(e)=>{
                                                setCemClass(parseInt(e.currentTarget.value))
                                            }}>
                                                <option value={15}>
                                                    Class 15
                                                </option>

                                                <option value={20}>
                                                    Class 20
                                                </option>

                                                <option value={25}>
                                                    Class 25
                                                </option>

                                                <option value={30}>
                                                    Class 30
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className={"row mt-4"}>
                                        <div className={"col"}>
                                            <h6>Volume: {volume} m<sup>3</sup></h6>

                                            <h6>Cement: {cement/50} Bags</h6>

                                        </div>
                                    </div>

                                    <div className={"row mt-4"}>
                                        <div className={"col-md-4"}>
                                            <label>Length</label>
                                            <input  className={"form-control"} value={length} onChange={(e)=>{
                                                if (/^\d*\.?\d*$/.test(e.currentTarget.value)) {
                                                    setLength(parseFloat(e.currentTarget.value))
                                                }


                                            }}/>
                                        </div>

                                        <div className={"col-md-4"}>
                                            <label>Width</label>
                                            <input  className={"form-control"} value={width} onChange={(e)=>{
                                                setWidth(parseFloat(e.currentTarget.value))

                                            }}/>
                                        </div>

                                        <div className={"col-md-4"}>
                                            <label>Height</label>
                                            <input  className={"form-control"} value={height} onChange={(e)=>{
                                                setHeight(parseFloat(e.currentTarget.value))

                                            }}/>
                                        </div>

                                        <div className={"col-md-4"}>
                                            <button className={"btn btn-success mt-2"} onClick={()=>{
                                                CalculateVolume()
                                            }}>Calculate Volume</button>
                                        </div>

                                        {cemClass === 15 && (
                                            <>
                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Cement</label>
                                                        <input  disabled={true} className={"form-control"} value={1/10 * volume * 1440 * 1.54} onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Cement Bags</label>
                                                        <input  disabled={true} className={"form-control"} value={1/10 * volume * 1440 * 1.54/50 + "  50kg Bags"} />
                                                    </div>


                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Sand</label>
                                                        <input  disabled={true} className={"form-control"} value={(3/10 * volume * 1600 * 1.54).toFixed(2) } onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Sand Trucks</label>
                                                        <input  disabled={true} className={"form-control"} value={(3/10 * volume * 1600 * 1.54 / 1000).toFixed(2) + "  Tonnes"} />
                                                    </div>
                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Ballast</label>
                                                        <input  disabled={true} className={"form-control"} value={(6/10 * volume * 1400 * 1.54).toFixed(2) } onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Ballast Trucks</label>
                                                        <input  disabled={true} className={"form-control"} value={(6/10 * volume * 1400 * 1.54 / 1000).toFixed(2) + "  Tonnes"} />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {cemClass === 20 && (
                                            <>
                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Cement</label>
                                                        <input  disabled={true} className={"form-control"} value={(1/7 * volume * 1440 * 1.54).toFixed(3)} onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Cement Bags</label>
                                                        <input  disabled={true} className={"form-control"} value={(1/7 * volume * 1440 * 1.54/50).toFixed(3) + "  50kg Bags"} />
                                                    </div>


                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Sand</label>
                                                        <input  disabled={true} className={"form-control"} value={(2/7 * volume * 1600 * 1.54).toFixed(2) } onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Sand Trucks</label>
                                                        <input  disabled={true} className={"form-control"} value={(2/7 * volume * 1600 * 1.54 / 1000).toFixed(2) + "  Tonnes"} />
                                                    </div>
                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Ballast</label>
                                                        <input  disabled={true} className={"form-control"} value={(4/7 * volume * 1400 * 1.54).toFixed(2) } onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Ballast Trucks</label>
                                                        <input  disabled={true} className={"form-control"} value={(4/7 * volume * 1400 * 1.54 / 1000).toFixed(2) + "  Tonnes"} />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {cemClass === 25 && (
                                            <>
                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Cement</label>
                                                        <input  disabled={true} className={"form-control"} value={(1/5.5 * volume * 1440 * 1.54).toFixed(3)} onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Cement Bags</label>
                                                        <input  disabled={true} className={"form-control"} value={(1/5.5 * volume * 1440 * 1.54/50).toFixed(3) + "  50kg Bags"} />
                                                    </div>


                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Sand</label>
                                                        <input  disabled={true} className={"form-control"} value={(1.5/5.5 * volume * 1600 * 1.54).toFixed(2) } onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Sand Trucks</label>
                                                        <input  disabled={true} className={"form-control"} value={(1.5/5.5 * volume * 1600 * 1.54 / 1000).toFixed(2) + "  Tonnes"} />
                                                    </div>
                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Ballast</label>
                                                        <input  disabled={true} className={"form-control"} value={(3/5.5 * volume * 1400 * 1.54).toFixed(2) } onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Ballast Trucks</label>
                                                        <input  disabled={true} className={"form-control"} value={(3/5.5 * volume * 1400 * 1.54 / 1000).toFixed(2) + "  Tonnes"} />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {cemClass === 30 && (
                                            <>
                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Cement</label>
                                                        <input  disabled={true} className={"form-control"} value={(1/4 * volume * 1440 * 1.54).toFixed(3)} onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Cement Bags</label>
                                                        <input  disabled={true} className={"form-control"} value={(1/4 * volume * 1440 * 1.54/50).toFixed(3) + "  50kg Bags"} />
                                                    </div>


                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Sand</label>
                                                        <input  disabled={true} className={"form-control"} value={(1/4 * volume * 1600 * 1.54).toFixed(2) } onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Sand Trucks</label>
                                                        <input  disabled={true} className={"form-control"} value={(1/4 * volume * 1600 * 1.54 / 1000).toFixed(2) + "  Tonnes"} />
                                                    </div>
                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-4"}>

                                                        <label>Ballast</label>
                                                        <input  disabled={true} className={"form-control"} value={(2/4 * volume * 1400 * 1.54).toFixed(2) } onChange={(e)=>{

                                                            setCement(e.currentTarget.value)

                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <label>Ballast Trucks</label>
                                                        <input  disabled={true} className={"form-control"} value={(2/4 * volume * 1400 * 1.54 / 1000).toFixed(2) + "  Tonnes"} />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className={"col-md-2"}>

                        </div>
                    </div>

                </section>
            )}

            {step===2 && (
                <>
                    <div className={"container-fluid"}>

                        <div className={"row mt-4"}>

                            <div className={"col-md-2"}>

                            </div>

                            <div className={"col-md-8"}>
                                <div className={"card"}>

                                    <div className={"card-header"}>
                                        Steel
                                    </div>


                                    <div className={"card-body"}>
                                        <select className={"form-control"} onChange={(e)=>{
                                            setType(e.currentTarget.value)
                                        }}>
                                            <option value={"colPad"}>Column Pad</option>
                                            <option value={"colStub"}>Column Stub</option>
                                            <option value={"beam"}>Beam</option>
                                            <option value={"slab"}>Slab</option>
                                            <option value={"col"}>Column</option>
                                        </select>
                                        {type==="colPad" && (
                                            <>
                                                <div className={"row mt-4"}>

                                                    <div className={"col-md-3"}>
                                                        <label>Length</label>
                                                        <input  className={"form-control"} type={"number"} value={length} onChange={(e)=>{
                                                            if (/^\d*\.?\d*$/.test(e.currentTarget.value)) {
                                                                setLength(parseFloat(e.currentTarget.value))
                                                            }


                                                        }}/>
                                                    </div>

                                                    <div className={"col-md-3"}>
                                                        <label>Width</label>
                                                        <input  className={"form-control"} type={"number"} value={width} onChange={(e)=>{
                                                            setWidth(parseFloat(e.currentTarget.value))

                                                        }}/>
                                                    </div>

                                                    <div className={"col-md-3"}>
                                                        <label>Height</label>
                                                        <input  className={"form-control"} type={"number"} value={height} onChange={(e)=>{
                                                            setHeight(parseFloat(e.currentTarget.value))

                                                        }}/>
                                                    </div>

                                                    <div className={"col-md-3"}>
                                                        <label>Number of Pads</label>
                                                        <input type={"number"} value={pads} className={"form-control"} onChange={(e)=>{
                                                            let converted = parseInt(e.currentTarget.value)
                                                            setPads(converted)
                                                        }}/>

                                                    </div>
                                                </div>
                                                <div className={"row mt-3"}>
                                                    <div className={"col"}>
                                                        <label>C/C Spacing along L - <em><sup>mm</sup></em></label>
                                                        <input type={"number"} className={"form-control"} onChange={(e)=>{

                                                            let converted = parseInt(e.currentTarget.value) / 1000
                                                            setLengthSpacing(converted)
                                                        }}/>
                                                    </div>

                                                    <div className={"col"}>
                                                        <label>C/C Spacing along W - <em><sup>mm</sup></em></label>
                                                        <input type={"number"}  className={"form-control"} onChange={(e)=>{

                                                            let converted = parseInt(e.currentTarget.value) / 1000
                                                            setWidthSpacing(converted)
                                                        }}/>
                                                    </div>





                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-3"}>

                                                        <button className={"btn btn-warning"} onClick={(e)=>{

                                                            CalculatePieces()
                                                            CalculatePiecesWidth()
                                                        }}>Calculate</button>
                                                        <br />
                                                        Pieces length: {pieces}  Pieces Width: {piecesWidth}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {type ==="colStub" &&(
                                            <>
                                                <div className={"row mt-4"}>
                                                    <div className={"col-md-3"}>
                                                        <label>Length - <sup> <em>mm</em> </sup> </label>
                                                        <input  className={"form-control"} type={"number"} value={length} onChange={(e)=>{
                                                            if (/^\d*\.?\d*$/.test(e.currentTarget.value)) {
                                                                setLength(parseFloat(e.currentTarget.value))
                                                            }


                                                        }}/>
                                                    </div>

                                                    <div className={"col-md-3"}>
                                                        <label>Width - <sup> <em>mm</em> </sup></label>
                                                        <input  className={"form-control"} type={"number"} value={width} onChange={(e)=>{
                                                            setWidth(parseFloat(e.currentTarget.value))

                                                        }}/>
                                                    </div>

                                                    <div className={"col-md-3"}>
                                                        <label>Height - <sup> <em>m</em> </sup></label>
                                                        <input  className={"form-control"} type={"number"} value={height} onChange={(e)=>{
                                                            setHeight(parseFloat(e.currentTarget.value))

                                                        }}/>
                                                    </div>

                                                    <div className={"col-md-3"}>
                                                        <label>Number of Columns</label>
                                                        <input type={"number"} value={cols} className={"form-control"} onChange={(e)=>{
                                                            let converted = parseInt(e.currentTarget.value)
                                                            setCols(converted)
                                                        }}/>

                                                    </div>

                                                    <div className={"col-md-3 mt-2"}>
                                                        <label>Overlaps - <sup> <em>mm</em> </sup></label>
                                                        <input type={"number"} className={"form-control"} value={overlap} onChange={(e)=>{
                                                            let converted = parseInt(e.currentTarget.value)
                                                            setOverlap(converted)
                                                        }}/>
                                                    </div>

                                                    <div className={"col-md-3 mt-2"}>
                                                        <label>Mainbar Pieces</label>
                                                        <input type={"number"} className={"form-control"} value={beamPieces} onChange={(e)=>{
                                                            let converted = parseInt(e.currentTarget.value)
                                                            setBeamPieces(converted)
                                                        }}/>
                                                    </div>

                                                    <div className={"col-md-3 mt-2"}>
                                                        <label>Ring Spacing - <sup> <em>mm</em> </sup></label>
                                                        <input type={"number"} className={"form-control"} value={ringSpacing} onChange={(e)=>{
                                                            let converted = parseInt(e.currentTarget.value)
                                                            setRingSpacing(converted)
                                                        }}/>
                                                    </div>

                                                    <div className={"col-md-3"}>
                                                        <button className={"btn btn-success mt-4"} onClick={(e)=>{
                                                            CalculateStubPieces()
                                                        }}>Calculate Stub Steel</button>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>

                                </div>

                                <div className={"card"}>
                                    <div className={"card-header"}>

                                        <div className={"row mt-4"}>
                                            <div className={"col"}>
                                                <label>Bar Type</label>
                                                <select className={"form-control"}  onChange={(e)=>{
                                                    setBarType(e.currentTarget.value)
                                                }}>
                                                    <option value={"r6"}>R6</option>
                                                    <option value={"d8"}>D8</option>
                                                    <option value={"d10"}>D10</option>
                                                    <option value={"d12"}>D12</option>
                                                    <option value={"d16"}>D16</option>
                                                    <option value={"d20"}>D20</option>
                                                    <option value={"d26"}>D26</option>
                                                    <option value={"d32"}>D32</option>
                                                </select>
                                            </div>
                                            {type === "colStub" && (
                                                <>
                                                    <div className={"col"}>
                                                        <label>Ring Bar Type</label>
                                                        <select className={"form-control"} onChange={(e)=>{
                                                            setRingBarType(e.currentTarget.value)
                                                        }}>
                                                            <option value={"r6"}>R6</option>
                                                            <option value={"d8"}>D8</option>
                                                            <option value={"d10"}>D10</option>
                                                            <option value={"d12"}>D12</option>
                                                            <option value={"d16"}>D16</option>
                                                            <option value={"d20"}>D20</option>
                                                            <option value={"d26"}>D26</option>
                                                            <option value={"d32"}>D32</option>

                                                        </select>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                    </div>

                                    <div className={"card-body"}>


                                        <table className={"table table-striped"}>


                                            {type === "colPad" && (
                                                <>
                                                    <thead>
                                                    <tr>
                                                        <th>

                                                        </th>

                                                        <th colSpan={6}>

                                                            Bottom Layer - {barType}

                                                        </th>

                                                        <th colSpan={3}>
                                                            Top Layer - {barType}
                                                        </th>
                                                        <th> </th>
                                                    </tr>
                                                    <tr>
                                                        <th>

                                                        </th>
                                                        <th colSpan={3}>B 1  </th>
                                                        <th colSpan={3}>B 2  </th>
                                                        <th colSpan={3}>T 1</th>
                                                        <th colSpan={3}>T 2</th>
                                                    </tr>


                                                    <tr>
                                                        <th>Member</th>
                                                        <th>Full Bars</th>
                                                        <th>Cut Len</th>
                                                        <th>Cut PCS</th>
                                                        <th>Full Bars</th>
                                                        <th>Cut Len</th>
                                                        <th>Cut PCS</th>
                                                        <th>Full Bars</th>
                                                        <th>Cut Len</th>
                                                        <th>Cut PCS</th>
                                                        <th>Full Bars</th>
                                                        <th>Cut Len</th>
                                                        <th>Cut PCS</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>

                                                    <>
                                                        <tr>
                                                            <td>
                                                                Pad
                                                            </td>

                                                            <td>
                                                                {pieces * pads} PCS
                                                            </td>

                                                            <td>
                                                                {b1Len * pads}
                                                            </td>
                                                            <td>
                                                                {((pieces * b1Len)/12)* pads}
                                                            </td>

                                                            <td>
                                                                {piecesWidth * pads} PCS
                                                            </td>
                                                            <td>
                                                                {b2Len * pads}
                                                            </td>
                                                            <td>
                                                                {((piecesWidth * b2Len)/12)* pads}
                                                            </td>

                                                            <td> </td>
                                                            <td> </td>
                                                            <td> </td>

                                                            <td> </td>
                                                            <td> </td>
                                                            <td> </td>
                                                        </tr>
                                                    </>

                                                    </tbody>
                                                </>
                                            )}
                                            {type === "colStub" && (
                                                <>
                                                    <thead>
                                                    <tr>
                                                        <th>

                                                        </th>

                                                        <th colSpan={3}>

                                                            Main Bar - {barType}

                                                        </th>

                                                        <th colSpan={3}>
                                                            Ring Bar - {ringBarType}
                                                        </th>
                                                    </tr>


                                                    <tr>
                                                        <th>Member</th>

                                                        <th>Full Bars</th>
                                                        <th>Cut Len</th>
                                                        <th>Cut PCS</th>

                                                        <th>Full Bars</th>
                                                        <th>Cut Len</th>
                                                        <th>Cut PCS</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td>Column Stubs</td>
                                                            <td>{results.fullBars} </td>
                                                            <td>{results.cut_len}</td>
                                                            <td>{results.cut_pcs} </td>
                                                            <td>{results.ringBars} </td>
                                                            <td>{results.rcut_len}</td>
                                                            <td>{results.rcut_pcs} </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                            )}

                                        </table>

                                    </div>
                                </div>


                                <div className={"card card-animate"}>

                                    <div className={"card-body"}>

                                        <p>
                                            Caution: Construction Calculation Assistance <br />

                                            This app is intended to provide you with valuable assistance in performing various construction calculations. However, it is important to note that the results generated by this app are based on specific input parameters and algorithms. They are not a substitute for professional engineering judgment and expertise. <br />

                                            Please keep the following points in mind: <br />

                                            1. **Supplemental Guidance:** The results provided by this app should be used as supplemental guidance and not as a definitive solution. Always consult with qualified engineers and construction professionals for accurate and comprehensive analysis.
                                            <br />
                                            2. **Variability:** Construction projects often involve unique site conditions, material properties, and other factors that may not be fully accounted for in this app. These variables can impact the accuracy of calculations.

                                            <br />
                                            3. **Review and Verification:** Before making any critical decisions based on the app's results, it is recommended to review, verify, and validate the calculations with appropriate experts.

                                            <br />
                                            4. **Liability:** The creators of this app and the supporting engineers shall not be held liable for any consequences arising from the use of this app. Users are responsible for independently assessing the suitability and accuracy of calculations for their specific projects.

                                            <br />
                                            Remember that construction calculations involve complex and multifaceted considerations. Always exercise caution and consult professionals to ensure the safety, integrity, and success of your construction projects.

                                            <br />-
                                            By using this app, you acknowledge and understand the limitations outlined above.

                                        </p>
                                    </div>
                                </div>


                            </div>

                            <div className={"col-md-2"}>

                            </div>

                        </div>

                    </div>

                </>
            )}
        </>
    )
}

export default ConcreteCalculators