import React, {useEffect, useState} from "react";
import {useBaseUrl, useAppName, usePostData, useGetData} from "../Utils";

const MaterialRequests: React.FC = () => {
    const appName = useAppName()
    const [step, setStep] = useState(1)
    const [brands, setBrands] = useState<any[]>([])
    const [items, setItems] = useState<any[]>([])
    const [selectedItem, setSelectedItem] = useState<any>()
    const [selectedBrand, setSelectedBrand] = useState<any>()
    const [selectedCategory, setSelectedCategory] = useState<any>(5)

    const [item, setItem] = useState('');
    const [itemName, setItemName] = useState("")
    const [brand, setBrand] = useState('');
    const [brandName, setBrandName] = useState<any>("")
    const [quantity, setQuantity] = useState('');
    const [units, setUnits] = useState('');
    const [unitName, setUnitName] = useState<any>("")
    const [category, setCategory] = useState('');
    const [budget, setBudget] = useState('');

    const [requests, setRequests] = useState<any[]>([])


    useEffect(()=>{
        GetItems()
        document.title = appName + ": Request Material Forms"
    }, [])



   const GetItems = async () =>{

        let url = `${useBaseUrl()}/suppliers/item-list`

        const formData = new FormData()

        formData.append("category", selectedCategory)

        const ity = await usePostData(url, formData)

       if(ity !=="undefined" && ity !== null) {

           //setItems(ity)

       }

       return

   }

   const GetCategories = async () => {

   }




    return(
        <>
            <div className={"row mt-3"}>

                <div className={"col"}>

                    <div className={"container-fluid"}>

                        <div className={"row mt-4"}>

                            <div className={"col-md-4"}> </div>
                            <div className={"col-md-4"}>
                                <h3>{appName} - Create New Request</h3>
                            </div>
                            <div className={"col-md-4"}> </div>

                        </div>

                        <div className={"row mt-4"}>

                            <div className={"col"}>

                                <div className={"container-fluid"}>

                                    <div className={"card"}>

                                        <div className={"card-body"}>

                                            <div >
                                                <h4>You have selected these items:</h4>
                                            </div>

                                            <div className={"selected-material mt-4"}>
                                                <table className={"table table-striped"}>
                                                    <thead>
                                                        <tr>
                                                            <th> </th>
                                                            <th><i className="bx bx-box"> </i>  Item</th>
                                                            <th><i className="bx bx-briefcase"> </i>   Brand</th>
                                                            <th><i className="bx bx-math"> </i>   Quantity</th>
                                                            <th><i className="bx bx-ruler"> </i>  Units</th>
                                                            <th><i className="bx bx-add-to-queue"> </i>   Category</th>
                                                            <th><i className="bx bx-pound"> </i>   Budget</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.values(requests).map((reqs)=>(
                                                            <tr>
                                                                <td>
                                                                    <input type={"checkbox"} className={"checkbox-primary"}/>
                                                                </td>
                                                                <td className={"text-primary"}>
                                                                    {reqs.brandName}
                                                                </td>
                                                                <td>
                                                                    {reqs.quantity}
                                                                </td>
                                                                <td>
                                                                    {reqs.unitName}
                                                                </td>
                                                                <td>
                                                                    {reqs.categoryName}
                                                                </td>
                                                                <td className={"text-danger"}>
                                                                    {reqs.budget}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>


                                </div>

                            </div>

                        </div>


                        <div className={"row mt-4"}>
                            <div className={"col"}>

                                <div className={"container-fluid"}>

                                    <div className={"card"}>

                                        <div className={"card-header"}>
                                            <h4>Add your items:</h4>
                                        </div>

                                        <div className={"card-body"}>

                                            <div className={"row mt-4"}>
                                                <div className={"col"}>

                                                    <label>Material Group:</label>
                                                    <select className={"form-control"} onChange={(e)=>{
                                                        setSelectedCategory(e.currentTarget.value)
                                                    }}>
                                                        <option>Choose Category</option>
                                                    </select>

                                                </div>

                                                <div className={"col"}>
                                                    <label>Choose Item:</label>
                                                    <select className={"form-control"} onChange={(e)=>{
                                                        setSelectedItem(e.currentTarget.value)
                                                        setSelectedBrand([])
                                                    }}>
                                                        <option>Choose One</option>
                                                        {Object.values(items).map((itr: any)=> (
                                                            <option value={itr.item_id}>
                                                                {itr.item}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>


                                                <div className={"col"}>
                                                    <label>Brand:</label>
                                                    <select value={selectedBrand} className={"form-control"} onChange={(e) => {
                                                        setSelectedItem(e.currentTarget.value);
                                                        const selectedItem = Object.values(items).find((itr: any) => itr.id === e.currentTarget.value);
                                                        if (selectedItem) {
                                                            setBrandName(selectedItem.brands[0].name);
                                                        } else {
                                                            setBrandName('');
                                                        }
                                                        setSelectedBrand([]);
                                                    }}>
                                                        <option>Choose One</option>
                                                        {Object.values(items).find((itr: any) => itr.id === selectedItem)?.brands.map((brand: any) => (
                                                            <option key={brand.id} value={brand.id}>
                                                                {brand.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className={"row mt-2"}>

                                                <div className={"col"}>
                                                    <label>Quantity Required:</label>
                                                    <input className={"form-control"}/>
                                                </div>

                                                <div className={"col"}>
                                                    <label>Your Budget:</label>
                                                    <input className={"form-control"}/>
                                                </div>

                                                <div className={"col"}>
                                                    <label>Due Date:</label>
                                                    <input type={"date"} className={"form-control"}/>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div
                style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    background: "#fff",
                    padding: "1rem",
                }}

                className={"row mt-4"}
            >
                <div className={"row mt-4"}>

                    <div className={"row"}>
                        <div className={"col"}>
                            <button className={"btn btn-success"} style={{width: "100%"}}>Add Item</button>
                        </div>

                        <div className={"col"}>
                            <button className={"btn btn-warning"} style={{width: "100%"}}>Remove Selected</button>
                        </div>

                        <div className={"col"}>
                            <button className={"btn btn-danger mt-3"} style={{width: "100%"}}>Submit List</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default MaterialRequests