import React, {useEffect} from 'react'

const ProJobs: React.FC = ()=> {

    useEffect(()=>{
        document.title = "BuilderSE - Find Jobs"
    }, [])

    return (
        <></>
    )
}

export default ProJobs