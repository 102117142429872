import React, {useEffect, useReducer} from "react";
import PlanHeader from "./PlanHeader";
import PlanMenu from "./PlanMenu";
import PlanFooter from "./PlanFooter";


const PlanProfile: React.FC = () => {

    const initialState = {
        step: 1,
        subStep: 2
    }

    const reducer = (state: any, action: any) => {

        switch (action.type) {

            case "SET_PAGE_HEADING":

                return {...state, pageHeading: action.payload}

            default:

                return state

        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(()=>{
        document.title = "BuilderSE" + state.pageHeading
    }, [])

    return (
        <>

            <PlanHeader />
            <PlanMenu />

            <div className={"main-content"}>

                <div className={"page-content"}>


                </div>

                <PlanFooter />
            </div>

        </>
    )
}

export default PlanProfile