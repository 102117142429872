import React, {useEffect, useState} from "react";
import {useBaseUrl, usePostData, useGetData} from '../Utils'
import {Link, useParams} from "react-router-dom"
import MaterialFind from "./finder";

const MaterialHome: React.FC = () => {

    const {category}: any = useParams()

    const ToCaps = (word: string) :string => {

        if (!word || word.length === 0) return word;

        return word.charAt(0).toUpperCase() + word.slice(1);

    }

    useEffect(()=>{

        document.title = "Material - " + category

    }, [category])


    const [materialId, setMaterialId] = useState<any>()
    const [pageHeading, setPageHeading] = useState("Material - " + ToCaps(category))
    const [selectedCat, setSelectedCat] = useState<any>()
    const [step, setStep] = useState(1)
    const [dealer, setDealer] = useState<any>()

    const [selectedCounty, setSelectedCounty] = useState("")
    const [selectedTown, setSelectedTown] = useState("")

    const [suppliers, setSuppliers] = useState<any[]>([])
    const [vendors, setVendors] = useState<any[]>([])

    const GetVendors = async() => {

        const url = `${useBaseUrl()}/material/dealers`

        const formData = new FormData()

        formData.append("category", category)
        formData.append("material_category", selectedCat)
        formData.append("town", selectedTown)
        formData.append("county", selectedCounty)



        const vendors = await usePostData(url, formData)

        setVendors(vendors)

    }

    return (
        <section>

            {step===1 &&(
                <section>
                    {category==="vendors" && (

                        <section>

                            <div className={"row mt-4"}>

                                <div className={"container-fluid"}>

                                    <div className={"row"}>

                                        <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>
                                        <div className={"col-xxl-6 col-xl-6 col-lg-6 col-md-8"}>

                                            <div className={"row mt-4"}> </div>

                                            <div className={"row mt-4"}> </div>

                                            <div className={"row mt-4"}> </div>

                                            <div className={"row mt-4 text-center"}> <h3>{pageHeading}</h3> </div>

                                            <div className={"row mt-4"}> </div>

                                            <div className={"row mt-4"}> </div>

                                            <div className={"row mt-4"}>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Vendors - Hardwares")
                                                        setSelectedCat(1)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/hardwares.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Hardwares</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Vendors - Factories")
                                                        setSelectedCat(2)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/factories.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Factories</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Vendors - Workshops")
                                                        setSelectedCat(3)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/workshops.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Workshops</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Vendors - Precast Yards")
                                                        setSelectedCat(4)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/yards.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1"> Precast Yards</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Vendors - Timber Yards")
                                                        setSelectedCat(5)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/timber.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Timber Yard</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Vendors - Workshops")
                                                        setSelectedCat(3)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/workshops.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Workshops</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <Link to={"/material"} className="card card-animate">
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/vendors.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Go Back</h5>
                                                        </div>
                                                    </Link>
                                                </div>

                                            </div>

                                        </div>
                                        <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>

                                    </div>

                                </div>
                            </div>
                        </section>
                    )}

                    {category === "suppliers" && (

                        <section>

                            <div className={"row mt-4"}>

                                <div className={"container-fluid"}>

                                    <div className={"row"}>

                                        <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>
                                        <div className={"col-xxl-6 col-xl-6 col-lg-6 col-md-8"}>

                                            <div className={"row mt-4"}> </div>

                                            <div className={"row mt-4"}> </div>

                                            <div className={"row mt-4"}> </div>

                                            <div className={"row mt-4 text-center"}> <h3>{pageHeading}</h3> </div>

                                            <div className={"row mt-4"}> </div>

                                            <div className={"row mt-4"}> </div>

                                            <div className={"row mt-4"}>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Material - Foundation")
                                                        setSelectedCat(1)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/foundation.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Foundation</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Material - Foundation")
                                                        setSelectedCat(2)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/walling.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Walling</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Material - Foundation")
                                                        setSelectedCat(2)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/roofing.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Roofing</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Material - Foundation")
                                                        setSelectedCat(2)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/windows.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Windows</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Material - Foundation")
                                                        setSelectedCat(2)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/doors.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Doors</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Material - Foundation")
                                                        setSelectedCat(2)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/finishes.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Finishes</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Material - Foundation")
                                                        setSelectedCat(2)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/fixtures.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Fixtures</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Material - Foundation")
                                                        setSelectedCat(2)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/mep.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">MEP</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Material - Foundation")
                                                        setSelectedCat(2)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/interior.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Interior</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" onClick={(e) => {
                                                        setPageHeading("Material - Foundation")
                                                        setSelectedCat(2)
                                                        setStep(2)
                                                    }}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/landscaping.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Landscaping</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <Link to={"/material"} className="card card-animate">
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/vendors.png`}
                                                                    alt="" className="img-fluid"/>
                                                            </div>
                                                            <h5 className="card-title mb-1">Go Back</h5>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>

                                    </div>

                                </div>
                            </div>
                        </section>
                    )}
                </section>
            )}

            {step===2 && (
                <section className={"container-fluid"}>
                    <MaterialFind category={category} selected={selectedCat} />
                </section>
            )}

        </section>
    )
}

export default MaterialHome