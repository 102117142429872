import React, {useEffect, useState} from 'react'
import {useBaseUrl, usePostData, useGetData} from '../Utils'
import ArtisanFooter from "./layouts/Footer";
import {Link} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {randomInt} from "crypto";
import UserProfile from "./Profile";


const ProSearch: React.FC = () => {
    const base_url = useBaseUrl();
    const [pageHeading, setPageHeading] = useState("Artisan Search")
    const [step, setStep] = useState(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [categories, setCategories] = useState<any[]>([])
    const [counties, setCounties] = useState<any[]>([])
    const [towns, setTowns] = useState<any[]>([])

    const [selectedTown, setSelectedTown] = useState("")
    const [selectedCounty, setSelectedCounty] = useState<any>("")
    const [selectedCat, setSelectedCat] = useState<any>(1)

    const [artisans, setProfessionals] = useState<any []>([])
    const [artisan, setArtisan] = useState<any>("")
    const [selectedArtisan, setSelectedArtisan] = useState<any>()

    useEffect(() => {
        document.title = pageHeading
        prefetch_counties()
        prefetch_categories()

    }, [])

    useEffect(()=>{
        GetProfessionals()
    }, [selectedCat])

    const prefetch_counties = async () => {

        let url = base_url + "/locations/counties"

        setIsLoading(true)

        try {

            let formData = new FormData()
            formData.append("county_code", selectedCounty)

            const response = await axios.post(url, formData).finally(() => {
                setIsLoading(false)
            })

            const {status, message, data} = response.data

            if (status == "success") {

                setCounties(data)


            } else {

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })


            }

        } catch (e) {

            setIsLoading(false)

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Unable to load counties!"
            })



        }

    }

    const prefetch_categories = async () => {

        let url = base_url + "/pros/categories"

        setIsLoading(true)

        try {

            const response = await axios.get(url).finally(() => {
                setIsLoading(false)
            })

            const {status, message, data} = response.data

            if (status == "success") {

                setCategories(data)



            } else {

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })


            }

        } catch (e) {

            setIsLoading(false)

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Server actively refused your connection."
            })


        }

    }

    const fetch_town = async (county_code: any) => {

        let url = base_url + "/locations/towns"

        setIsLoading(true)

        try {

            let formData = new FormData()

            formData.append("county_code", county_code)

            const response = await axios.post(url, formData).finally(() => {
                setIsLoading(false)
            })

            const {status, message, data} = response.data

            if (status === "success") {

                setTowns(data)

            } else {

                await Swal.fire({
                    icon: "warning",
                    title: "Error",
                    text: message
                })

            }


        } catch (e) {

            setIsLoading(false)

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Failed to fetch towns!"
            })

        }

    }

    const GetProfessionals = async () => {



        const url = `${useBaseUrl()}/pros/get-pros`

        const formData = new FormData()

        formData.append("category", selectedCat)
        formData.append("town", selectedTown)
        formData.append("county", selectedCounty)
        formData.append("artisan_id", artisan)

        const prozz = await usePostData(url, formData)

        if (prozz === "undefined"){


        }else{

            setProfessionals(prozz)

        }



    }

    const mapArrayToCards = (array: any, columns: any) => {
        const cards = [];
        for (let i = 0; i < array.length; i += columns) {
            const row = [];
            for (let j = i; j < i + columns && j < array.length; j++) {
                row.push(array[j]);
            }
            cards.push(row);
        }
        return cards;
    };

    const columns = 4;

    const cards = mapArrayToCards(artisans, columns);

    return (
        <>
            <div className={"container-fluid"}>
                <div className={"row"}>

                    <div className={"container-fluid"}>

                        <div className={"row"}>

                            <div className={"col-xxl-1 col-xl-1 col-lg-1 col-md-1"}></div>

                            <div className={"col-xxl-10 col-xl-10 col-lg-10 col-md-10"}>

                                <div className={"row mt-4"}></div>

                                <div className={"row mt-4 text-center"}><h3>{pageHeading}</h3></div>

                                <div className={"row mt-4"}></div>

                                <div className={"row mt-4"}></div>

                                {step === 1 && (
                                    <>
                                        <div className={"row mt-4"}>
                                            <div className={"col"}>
                                                <div className="card card-animate" onClick={(e) => {
                                                    setPageHeading("Search Civil Structural Engineers")
                                                    setSelectedCat(2)
                                                    setStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/engineers.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Civil Structural Engineers</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate" onClick={(e) => {
                                                    setPageHeading("Search Architects")
                                                    setSelectedCat(1)
                                                    setStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/architects.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Architects</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate" onClick={(e) => {
                                                    setPageHeading("Search Quantity Surveyors")
                                                    setSelectedCat(3)
                                                    setStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/surveyors.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Quantity Surveyors</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate" onClick={(e) => {
                                                    setPageHeading("Search Planners")
                                                    setSelectedCat(4)
                                                    setStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/planners.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Planners</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"row mt-4"}>

                                            <div className={"col"}>
                                                <div className="card card-animate">
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/create.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Register</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate">
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/teams.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Professionals' Teams</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <Link to={"/pros"} className="card card-animate">
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/layout.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Home</h5>
                                                    </div>
                                                </Link>
                                            </div>


                                        </div>
                                    </>
                                )}

                                {step === 2 && (
                                    <>

                                        <div className="card">
                                            <div className="card-header border-0 rounded">
                                                <div className="row g-2">
                                                    <div className="col-xl-3">
                                                        <div className="search-box">
                                                            <input type="text" className="form-control search"
                                                                   placeholder="Type something"/>
                                                            <i className="ri-search-line search-icon"> </i>
                                                        </div>
                                                    </div>

                                                    <div className="col ms-auto">
                                                        <div>
                                                            <select className="form-control" value={selectedCat}
                                                                    onChange={
                                                                        (e) => {
                                                                            setSelectedCat(e.currentTarget.value);

                                                                        }}>

                                                                <option value={""}>All</option>

                                                                {Object.values(categories).map((cats: any) => (
                                                                    <option value={cats.id}>
                                                                        {cats.cat}
                                                                    </option>
                                                                ))}


                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={"col ms-auto"}>
                                                        <select className="form-control" value={selectedCounty}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const selectedCountyId = parseInt(e.currentTarget.value);
                                                                    setSelectedCounty(selectedCountyId)
                                                                    fetch_town(selectedCountyId)

                                                                }}
                                                        >
                                                            <option value={""}>All Counties</option>
                                                            {
                                                                selectedCounty != "all" && (
                                                                    <>
                                                                        {Object.values(counties).map((county: any) => (
                                                                            <>
                                                                                <option
                                                                                    value={county.county_code}>{county.county}</option>
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                )
                                                            }

                                                        </select>
                                                    </div>
                                                    <div className={"col ms-auto"}>

                                                        <select className="form-control" disabled={false}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const selectedTown = parseInt(e.currentTarget.value);

                                                                }}>
                                                            <option value={""}>All Towns</option>
                                                            {
                                                                Object.values(towns).map((town: any) => (
                                                                    <option value={town.id}> {town.name}
                                                                    </option>
                                                                ))
                                                            }

                                                        </select>
                                                    </div>

                                                    <div className="col-lg-auto">
                                                        <div className="hstack gap-2">
                                                            <button type="button" className="btn btn-danger"><i
                                                                className="ri-equalizer-fill me-1 align-bottom"> </i> Filters
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>

                                            {artisans.map((card: any) => (
                                                <div className={"col-md-3"}>
                                                    <div className="card">
                                                        <div className="card-body text-center p-4">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/companies/img-1.png`}
                                                                alt="" height="45"/>
                                                            <h5 className="mb-1 mt-4"><a href="#"
                                                                                         className="link-primary">{card.fname} {card.lname}</a>
                                                            </h5>
                                                            <p className="text-muted mb-4">{card.category}</p>

                                                            <p className="text-muted mb-4">{card.county} {card.town}</p>

                                                            <div className="row mt-4">
                                                                <div
                                                                    className="col-lg-6 border-end-dashed border-end">
                                                                    <h5>{22}</h5>
                                                                    <span className="text-muted">Ongoing</span>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <h5>{45}</h5>
                                                                    <span className="text-muted">Capacity</span>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <form method="POST" onSubmit={(e) => {
                                                                    setSelectedArtisan(card)

                                                                    setStep(3)
                                                                    setPageHeading(`${card.fname}  ${card.lname}'s Pofile `)
                                                                }}>

                                                                    <input type="hidden" name="clinic_id"
                                                                           value={2}/>
                                                                    <input type="submit" value="View details"
                                                                           className="btn btn-rounded btn-outline-primary w-100"/>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}


                                        </div>

                                    </>
                                )}

                                {step === 3 && (
                                    <section>

                                        <UserProfile artisan={selectedArtisan} />

                                    </section>
                                )}


                            </div>

                            <div className={"col-xxl-1 col-xl-1 col-lg-1 col-md-1"}></div>

                        </div>

                    </div>

                </div>
            </div>

            <ArtisanFooter/>

        </>
    )
}

export default ProSearch