import React, {useEffect} from 'react'

const ArtisanJobs: React.FC = ()=> {

    useEffect(()=>{
        document.title = "BuilderSE - Find Jobs"
    }, [])

    return (
        <></>
    )
}

export default ArtisanJobs