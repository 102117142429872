import React from "react";
import {format} from "date-fns";
import {Link} from "react-router-dom";

interface Artisan {
    artisan: any
}

const UserProfile: React.FC<Artisan> = ({artisan}) => {

    const date = new Date(artisan.created);
   const created = format(date, 'dd MMM yyyy HH:mm:ss');

    return (

        <section className={"container-fluid"}>

            <div className="profile-foreground position-relative mx-n4 mt-n4">
                <div className="profile-wid-bg">
                    <img src ={`${process.env.PUBLIC_URL}/assets/images/profile-bg.jpg`} alt="" className="profile-wid-img"/>
                </div>
            </div>
            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
                <div className="row g-4">
                    <div className="col-auto">
                        <div className="avatar-lg">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/${artisan.logo}`} alt="user-img" className="img-thumbnail rounded-circle"/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="p-2">
                            <h3 className="text-white mb-1">{artisan.fname} {artisan.lname}</h3>
                            <p className="text-white text-opacity-75">Artisan category: {artisan.category}</p>
                            <div className="hstack text-white-50 gap-1">
                                <div className="me-2"><i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{artisan.town},
                                    {artisan.county}
                                </div>
                                <div>
                                    <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle"> </i>Builders' Search
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-auto order-last order-lg-0">
                        <div className="row text text-white-50 text-center">
                            <div className="col-lg-6 col-4">
                                <div className="p-2">
                                    <h4 className="text-white mb-1">24.3K</h4>
                                    <p className="fs-14 mb-0">Interests</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-4">
                                <div className="p-2">
                                    <h4 className="text-white mb-1">1.3K</h4>
                                    <p className="fs-14 mb-0">Engagements</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div>
                        <div className="d-flex profile-wrapper">

                            <ul className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link fs-14 active" data-bs-toggle="tab" href="#overview-tab" role="tab">
                                        <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Overview</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link fs-14" data-bs-toggle="tab" href="#activities" role="tab">
                                        <i className="ri-list-unordered d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Portfolio Gallery</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link fs-14" data-bs-toggle="tab" href="#projects" role="tab">
                                        <i className="ri-price-tag-line d-inline-block d-md-none"></i> <span
                                        className="d-none d-md-inline-block">Portfolio Projects</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link fs-14" data-bs-toggle="tab" href="#documents" role="tab">
                                        <i className="ri-folder-4-line d-inline-block d-md-none"></i> <span
                                        className="d-none d-md-inline-block">Portfolio Documents</span>
                                    </a>
                                </li>
                            </ul>
                            <div className="flex-shrink-0">
                                <a href="pages-profile-settings.html" className="btn btn-success"><i
                                    className="ri-checkbox-fill align-bottom"> </i> Engage Artisan</a>
                            </div>

                            &nbsp;

                            <div className="flex-shrink-0">
                                <Link to={"/artisans"} className="btn btn-success"><i
                                    className="ri-checkbox-fill align-bottom"> </i> Back</Link>
                            </div>
                        </div>

                        <div className="tab-content pt-4 text-muted">
                            <div className="tab-pane active" id="overview-tab" role="tabpanel">
                                <div className="row">
                                    <div className="col-xxl-3">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title mb-5">Complete Your Profile</h5>
                                                <div
                                                    className="progress animated-progress custom-progress progress-label">
                                                    <div className="progress-bar bg-danger" role="progressbar"
                                                         style={{width: "30%"}} aria-valuenow={30} aria-valuemin={0}
                                                         aria-valuemax={100}>
                                                        <div className="label">30%</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title mb-3">Info</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-borderless mb-0">
                                                        <tbody>
                                                        <tr>
                                                            <th className="ps-0" scope="row">Full Name :</th>
                                                            <td className="text-muted">{artisan.fname} {artisan.lname}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="ps-0" scope="row">Mobile :</th>
                                                            <td className="text-muted">Engage Artisan to View</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="ps-0" scope="row">E-mail :</th>
                                                            <td className="text-muted">Engage Artisan to View</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="ps-0" scope="row">Location :</th>
                                                            <td className="text-muted">{artisan.town}, {artisan.county} County
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th className="ps-0" scope="row">Joining Date</th>
                                                            <td className="text-muted">
                                                                {created}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title mb-4">Portfolio</h5>
                                                <div className="d-flex flex-wrap gap-2">
                                                    <div>
                                                        <a href="javascript:void(0);" className="avatar-xs d-block">
                                                                    <span
                                                                        className="avatar-title rounded-circle fs-16 bg-dark text-light">
                                                                        <i className="ri-github-fill"></i>
                                                                    </span>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <a href="javascript:void(0);" className="avatar-xs d-block">
                                                                    <span
                                                                        className="avatar-title rounded-circle fs-16 bg-primary">
                                                                        <i className="ri-global-fill"></i>
                                                                    </span>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <a href="javascript:void(0);" className="avatar-xs d-block">
                                                                    <span
                                                                        className="avatar-title rounded-circle fs-16 bg-success">
                                                                        <i className="ri-dribbble-fill"></i>
                                                                    </span>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <a href="javascript:void(0);" className="avatar-xs d-block">
                                                                    <span
                                                                        className="avatar-title rounded-circle fs-16 bg-danger">
                                                                        <i className="ri-pinterest-fill"></i>
                                                                    </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title mb-4">Skills</h5>
                                                <div className="d-flex flex-wrap gap-2 fs-15">
                                                    <a href="javascript:void(0);"
                                                       className="badge bg-primary-subtle text-primary">Photoshop</a>
                                                    <a href="javascript:void(0);"
                                                       className="badge bg-primary-subtle text-primary">illustrator</a>
                                                    <a href="javascript:void(0);"
                                                       className="badge bg-primary-subtle text-primary">HTML</a>
                                                    <a href="javascript:void(0);"
                                                       className="badge bg-primary-subtle text-primary">CSS</a>
                                                    <a href="javascript:void(0);"
                                                       className="badge bg-primary-subtle text-primary">Javascript</a>
                                                    <a href="javascript:void(0);"
                                                       className="badge bg-primary-subtle text-primary">Php</a>
                                                    <a href="javascript:void(0);"
                                                       className="badge bg-primary-subtle text-primary">Python</a>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-4">
                                                    <div className="flex-grow-1">
                                                        <h5 className="card-title mb-0">Suggestions</h5>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div className="dropdown">
                                                            <a href="#" role="button" id="dropdownMenuLink2"
                                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-more-2-fill fs-14"></i>
                                                            </a>

                                                            <ul className="dropdown-menu dropdown-menu-end"
                                                                aria-labelledby="dropdownMenuLink2">
                                                                <li><a className="dropdown-item" href="#">View</a>
                                                                </li>
                                                                <li><a className="dropdown-item" href="#">Edit</a>
                                                                </li>
                                                                <li><a className="dropdown-item" href="#">Delete</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="d-flex align-items-center py-3">
                                                        <div className="avatar-xs flex-shrink-0 me-3">
                                                            <img src="assets/images/users/avatar-3.jpg" alt=""
                                                                 className="img-fluid rounded-circle"/>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div>
                                                                <h5 className="fs-13 mb-1">Esther James</h5>
                                                                <p className="fs-13 text-muted mb-0">Frontend
                                                                    Developer</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-2">
                                                            <button type="button"
                                                                    className="btn btn-sm btn-outline-success"><i
                                                                className="ri-user-add-line align-middle"></i></button>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center py-3">
                                                        <div className="avatar-xs flex-shrink-0 me-3">
                                                            <img src="assets/images/users/avatar-4.jpg" alt=""
                                                                 className="img-fluid rounded-circle"/>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div>
                                                                <h5 className="fs-13 mb-1">Jacqueline Steve</h5>
                                                                <p className="fs-13 text-muted mb-0">UI/UX Designer
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-2">
                                                            <button type="button"
                                                                    className="btn btn-sm btn-outline-success"><i
                                                                className="ri-user-add-line align-middle"></i></button>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center py-3">
                                                        <div className="avatar-xs flex-shrink-0 me-3">
                                                            <img src="assets/images/users/avatar-5.jpg" alt=""
                                                                 className="img-fluid rounded-circle"/>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div>
                                                                <h5 className="fs-13 mb-1">George Whalen</h5>
                                                                <p className="fs-13 text-muted mb-0">Backend
                                                                    Developer</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0 ms-2">
                                                            <button type="button"
                                                                    className="btn btn-sm btn-outline-success"><i
                                                                className="ri-user-add-line align-middle"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-4">
                                                    <div className="flex-grow-1">
                                                        <h5 className="card-title mb-0">Popular Posts</h5>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div className="dropdown">
                                                            <a href="#" role="button" id="dropdownMenuLink1"
                                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-more-2-fill fs-14"></i>
                                                            </a>

                                                            <ul className="dropdown-menu dropdown-menu-end"
                                                                aria-labelledby="dropdownMenuLink1">
                                                                <li><a className="dropdown-item" href="#">View</a>
                                                                </li>
                                                                <li><a className="dropdown-item" href="#">Edit</a>
                                                                </li>
                                                                <li><a className="dropdown-item" href="#">Delete</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-4">
                                                    <div className="flex-shrink-0">
                                                        <img src="assets/images/small/img-4.jpg" alt="" height="50"
                                                             className="rounded"/>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3 overflow-hidden">
                                                        <a href="javascript:void(0);">
                                                            <h6 className="text-truncate fs-13">Design your apps in
                                                                your own way</h6>
                                                        </a>
                                                        <p className="text-muted mb-0">15 Dec 2021</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-4">
                                                    <div className="flex-shrink-0">
                                                        <img src="assets/images/small/img-5.jpg" alt="" height="50"
                                                             className="rounded"/>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3 overflow-hidden">
                                                        <a href="javascript:void(0);">
                                                            <h6 className="text-truncate fs-13">Smartest
                                                                Applications for Business</h6>
                                                        </a>
                                                        <p className="text-muted mb-0">28 Nov 2021</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img src="assets/images/small/img-6.jpg" alt="" height="50"
                                                             className="rounded"/>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3 overflow-hidden">
                                                        <a href="javascript:void(0);">
                                                            <h6 className="text-truncate fs-13">How to get creative
                                                                in your work</h6>
                                                        </a>
                                                        <p className="text-muted mb-0">21 Nov 2021</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-xxl-9">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title mb-3">About</h5>
                                                <p>{artisan.info.about}</p>
                                                <p>{artisan.info.bio}</p>
                                                <div className="row">
                                                    <div className="col-6 col-md-4">
                                                        <div className="d-flex mt-4">
                                                            <div
                                                                className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                <div
                                                                    className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                    <i className="ri-user-2-fill"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <p className="mb-1">Designation :</p>
                                                                <h6 className="text-truncate mb-0">{artisan.category}</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-md-4">
                                                        <div className="d-flex mt-4">
                                                            <div
                                                                className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                <div
                                                                    className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                    <i className="ri-global-line"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <p className="mb-1">Website :</p>
                                                                <Link to="/artisans" className="fw-semibold">Engage Artisan First</Link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header align-items-center d-flex">
                                                        <h4 className="card-title mb-0  me-2">Recent Activity</h4>
                                                        <div className="flex-shrink-0 ms-auto">
                                                            <ul className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                                                role="tablist">
                                                                <li className="nav-item">
                                                                    <a className="nav-link active" data-bs-toggle="tab"
                                                                       href="#today" role="tab">
                                                                        Today
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" data-bs-toggle="tab"
                                                                       href="#weekly" role="tab">
                                                                        Weekly
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" data-bs-toggle="tab"
                                                                       href="#monthly" role="tab">
                                                                        Monthly
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="tab-content text-muted">
                                                            <div className="tab-pane active" id="today" role="tabpanel">

                                                            </div>
                                                            <div className="tab-pane" id="weekly" role="tabpanel">

                                                            </div>
                                                            <div className="tab-pane" id="monthly" role="tabpanel">

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Projects</h5>

                                                <div className="swiper project-swiper mt-n4">
                                                    <div className="d-flex justify-content-end gap-2 mb-2">
                                                        <div className="slider-button-prev">
                                                            <div className="avatar-title fs-18 rounded px-1">
                                                                <i className="ri-arrow-left-s-line"></i>
                                                            </div>
                                                        </div>
                                                        <div className="slider-button-next">
                                                            <div className="avatar-title fs-18 rounded px-1">
                                                                <i className="ri-arrow-right-s-line"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-wrapper">
                                                        <div className="swiper-slide">

                                                        </div>

                                                        <div className="swiper-slide">


                                                        </div>

                                                        <div className="swiper-slide">


                                                        </div>

                                                        <div className="swiper-slide">


                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                    </div>

                                </div>

                            </div>
                            <div className="tab-pane fade" id="activities" role="tabpanel">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title mb-3">Activities</h5>

                                    </div>

                                </div>

                            </div>

                            <div className="tab-pane fade" id="projects" role="tabpanel">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-3 col-sm-6">
                                                <div className="card profile-project-card shadow-none profile-project-warning">
                                                    <div className="card-body p-4">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1 text-muted overflow-hidden">
                                                                <h5 className="fs-14 text-truncate"><a href="#" className="text-body"> Walling</a>
                                                                </h5>
                                                                <p className="text-muted text-truncate mb-0">Last
                                                                    Update : <span className="fw-semibold text-body">2 years
                                                                                Ago</span></p>
                                                            </div>
                                                            <div className="flex-shrink-0 ms-2">
                                                                <div
                                                                    className="badge bg-warning-subtle text-warning fs-10">
                                                                    Inprogress
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex mt-4">
                                                            <div className="flex-grow-1">
                                                                <div className="d-flex align-items-center gap-2">
                                                                    <div>
                                                                        <h5 className="fs-12 text-muted mb-0">
                                                                            Members :</h5>
                                                                    </div>
                                                                    <div className="avatar-group">
                                                                        <div className="avatar-group-item">
                                                                            <div className="avatar-xs">
                                                                                <img
                                                                                    src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-1.jpg`}
                                                                                    alt=""
                                                                                    className="rounded-circle img-fluid"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="avatar-group-item">
                                                                            <div className="avatar-xs">
                                                                                <img
                                                                                    src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`}
                                                                                    alt=""
                                                                                    className="rounded-circle img-fluid"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="avatar-group-item">
                                                                            <div className="avatar-xs">
                                                                                <div
                                                                                    className="avatar-title rounded-circle bg-light text-primary">
                                                                                    J
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="col-lg-12">
                                                <div className="mt-4">
                                                    <ul className="pagination pagination-separated justify-content-center mb-0">
                                                        <li className="page-item disabled">
                                                            <a href="javascript:void(0);" className="page-link"><i className="mdi mdi-chevron-left"> </i></a>
                                                        </li>
                                                        <li className="page-item active">
                                                            <a href="javascript:void(0);" className="page-link">1</a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a href="javascript:void(0);" className="page-link">2</a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a href="javascript:void(0);" className="page-link">3</a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a href="javascript:void(0);" className="page-link">4</a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a href="javascript:void(0);" className="page-link">5</a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a href="javascript:void(0);" className="page-link"><i
                                                                className="mdi mdi-chevron-right"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="tab-pane fade" id="documents" role="tabpanel">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center mb-4">
                                            <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
                                            <div className="flex-shrink-0">
                                                <input className="form-control d-none" type="file" id="formFile" />
                                                    <label htmlFor="formFile" className="btn btn-danger"><i
                                                        className="ri-upload-2-fill me-1 align-bottom"></i> Upload
                                                        File</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table table-borderless align-middle mb-0">
                                                        <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">File Name</th>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Size</th>
                                                            <th scope="col">Upload Date</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="avatar-sm">
                                                                        <div
                                                                            className="avatar-title bg-primary-subtle text-primary rounded fs-20">
                                                                            <i className="ri-file-zip-fill"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 flex-grow-1">
                                                                        <h6 className="fs-14 mb-0"><a
                                                                            href="javascript:void(0)"
                                                                            className="text-body">Artboard-documents.zip</a>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>Zip File</td>
                                                            <td>4.57 MB</td>
                                                            <td>12 Dec 2021</td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <a href="javascript:void(0);"
                                                                       className="btn btn-light btn-icon"
                                                                       id="dropdownMenuLink15" data-bs-toggle="dropdown"
                                                                       aria-expanded="true">
                                                                        <i className="ri-equalizer-fill"></i>
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-end"
                                                                        aria-labelledby="dropdownMenuLink15">
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-eye-fill me-2 align-middle text-muted"></i>View</a>
                                                                        </li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-download-2-fill me-2 align-middle text-muted"></i>Download</a>
                                                                        </li>
                                                                        <li className="dropdown-divider"></li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Delete</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="avatar-sm">
                                                                        <div
                                                                            className="avatar-title bg-danger-subtle text-danger rounded fs-20">
                                                                            <i className="ri-file-pdf-fill"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 flex-grow-1">
                                                                        <h6 className="fs-14 mb-0"><a
                                                                            href="javascript:void(0);"
                                                                            className="text-body">Bank
                                                                            Management System</a></h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>PDF File</td>
                                                            <td>8.89 MB</td>
                                                            <td>24 Nov 2021</td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <a href="javascript:void(0);"
                                                                       className="btn btn-light btn-icon"
                                                                       id="dropdownMenuLink3" data-bs-toggle="dropdown"
                                                                       aria-expanded="true">
                                                                        <i className="ri-equalizer-fill"></i>
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-end"
                                                                        aria-labelledby="dropdownMenuLink3">
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-eye-fill me-2 align-middle text-muted"></i>View</a>
                                                                        </li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-download-2-fill me-2 align-middle text-muted"></i>Download</a>
                                                                        </li>
                                                                        <li className="dropdown-divider"></li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Delete</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="avatar-sm">
                                                                        <div
                                                                            className="avatar-title bg-secondary-subtle text-secondary rounded fs-20">
                                                                            <i className="ri-video-line"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 flex-grow-1">
                                                                        <h6 className="fs-14 mb-0"><a
                                                                            href="javascript:void(0);"
                                                                            className="text-body">Tour-video.mp4</a>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>MP4 File</td>
                                                            <td>14.62 MB</td>
                                                            <td>19 Nov 2021</td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <a href="javascript:void(0);"
                                                                       className="btn btn-light btn-icon"
                                                                       id="dropdownMenuLink4" data-bs-toggle="dropdown"
                                                                       aria-expanded="true">
                                                                        <i className="ri-equalizer-fill"></i>
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-end"
                                                                        aria-labelledby="dropdownMenuLink4">
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-eye-fill me-2 align-middle text-muted"></i>View</a>
                                                                        </li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-download-2-fill me-2 align-middle text-muted"></i>Download</a>
                                                                        </li>
                                                                        <li className="dropdown-divider"></li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Delete</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="avatar-sm">
                                                                        <div
                                                                            className="avatar-title bg-success-subtle text-success rounded fs-20">
                                                                            <i className="ri-file-excel-fill"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 flex-grow-1">
                                                                        <h6 className="fs-14 mb-0"><a
                                                                            href="javascript:void(0);"
                                                                            className="text-body">Account-statement.xsl</a>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>XSL File</td>
                                                            <td>2.38 KB</td>
                                                            <td>14 Nov 2021</td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <a href="javascript:void(0);"
                                                                       className="btn btn-light btn-icon"
                                                                       id="dropdownMenuLink5" data-bs-toggle="dropdown"
                                                                       aria-expanded="true">
                                                                        <i className="ri-equalizer-fill"></i>
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-end"
                                                                        aria-labelledby="dropdownMenuLink5">
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-eye-fill me-2 align-middle text-muted"></i>View</a>
                                                                        </li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-download-2-fill me-2 align-middle text-muted"></i>Download</a>
                                                                        </li>
                                                                        <li className="dropdown-divider"></li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Delete</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="avatar-sm">
                                                                        <div
                                                                            className="avatar-title bg-info-subtle text-info rounded fs-20">
                                                                            <i className="ri-folder-line"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 flex-grow-1">
                                                                        <h6 className="fs-14 mb-0"><a
                                                                            href="javascript:void(0);"
                                                                            className="text-body">Project
                                                                            Screenshots Collection</a>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>Floder File</td>
                                                            <td>87.24 MB</td>
                                                            <td>08 Nov 2021</td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <a href="javascript:void(0);"
                                                                       className="btn btn-light btn-icon"
                                                                       id="dropdownMenuLink6" data-bs-toggle="dropdown"
                                                                       aria-expanded="true">
                                                                        <i className="ri-equalizer-fill"></i>
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-end"
                                                                        aria-labelledby="dropdownMenuLink6">
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-eye-fill me-2 align-middle"></i>View</a>
                                                                        </li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-download-2-fill me-2 align-middle"></i>Download</a>
                                                                        </li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-delete-bin-5-line me-2 align-middle"></i>Delete</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="avatar-sm">
                                                                        <div
                                                                            className="avatar-title bg-danger-subtle text-danger rounded fs-20">
                                                                            <i className="ri-image-2-fill"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 flex-grow-1">
                                                                        <h6 className="fs-14 mb-0"><a
                                                                            href="javascript:void(0);"
                                                                            className="text-body">Velzon-logo.png</a>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>PNG File</td>
                                                            <td>879 KB</td>
                                                            <td>02 Nov 2021</td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <a href="javascript:void(0);"
                                                                       className="btn btn-light btn-icon"
                                                                       id="dropdownMenuLink7" data-bs-toggle="dropdown"
                                                                       aria-expanded="true">
                                                                        <i className="ri-equalizer-fill"></i>
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-end"
                                                                        aria-labelledby="dropdownMenuLink7">
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-eye-fill me-2 align-middle"></i>View</a>
                                                                        </li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-download-2-fill me-2 align-middle"></i>Download</a>
                                                                        </li>
                                                                        <li><a className="dropdown-item"
                                                                               href="javascript:void(0);"><i
                                                                            className="ri-delete-bin-5-line me-2 align-middle"></i>Delete</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="text-center mt-3">
                                                    <a href="javascript:void(0);" className="text-success "><i
                                                        className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                                        Load more </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </section>

    )

}

export default UserProfile