import React from "react";

const ArtisanFooter: React.FC = () => {
    let currentYear;

    return(
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <script type="text/javascript">{`document.write(${currentYear})`}</script>
                        © Builders Search Engine - Helix <sup> <span className="badge-danger"> Beta </span>  </sup>.
                    </div>
                    <div className="col-sm-8">
                        <div className="text-sm-end d-none d-sm-block">

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default ArtisanFooter