import React, {useEffect, useReducer} from "react"

const CreatePlan: React.FC = () => {

    useEffect(()=>{
        document.title = "BuildserSE Plan Manager - Create Plan"
    }, [])

    const initialState = {
        step: 0,
        subStep: 0,
        cardStep: 0,
        planName: "",
        planFloors: 0,
        houseType: "residential",
        foundation: "traditional",
        roofType: "traditional",

    }

    const reducer = (state: any, action: any) => {

        switch (action.type){

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            case "SET_CARD_STEP":
                return {...state, cardStep: action.payload}

            case "SET_PLAN_NAME":
                return {...state, planName: action.payload}

            case "SET_PLAN_FLOORS":
                return {...state, planFloors: action.payload}

            case "SET_HOUSE_TYPE":
                return {...state, houseType: action.payload}

            case "SET_ROOF_TYPE":
                return {...state, roofType: action.payload}

            case "SET_FOUNDATION":
                return {...state, foundation: action.payload}

            default:
                return state
        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const RenderFloors = (floors: number = state.planFloors) => {

        let floorFields = ""

        for (let floor =1; floor <= floors; floor++){

            floorFields +=
                `
                    <div className="row mt-4">
                    
                        <div className="col">
                        
                            {floor}
                        
                        </div>
                    
                    </div>
                
                `
        }

        return floorFields

    }

    return(
        <>
            <div className={"row mt-4"}>

                <div className={"col"}>

                    <div className={"card"} style={{transform: "none", fontSize: "15px"}}>

                        <div className={"card-header text-center"} style={{fontWeight: "bold"}}>

                            {state.cardStep === 0 && (
                                <h5>
                                    Warming Up!
                                </h5>
                            )}

                            {state.cardStep === 1 && (
                                <h5>
                                    Basic Information about your house plan.
                                </h5>
                            )}

                            {state.cardStep === 2 && (
                                <h5>
                                    Information about the house rooms
                                </h5>
                            )}

                        </div>

                        <div className={"card-body"}>
                            {state.cardStep === 0 && (
                                <>
                                    <p>Welcome to BuilderSE House Plan Creator User!</p>
                                    <p>We will take you through several steps during this process.</p>
                                    <p>Worry not! This is absolutely painless!</p>
                                    <p>Why multiple steps? Because it helps keep your account safer!</p>
                                    <p>What steps wil I take?</p>
                                    <p>The first step takes basic info for your plan, the 2nd step dimensions, the 3rd step layouts, the fourth step renders, a final 5th step for additional info and submitting</p>
                                </>
                            )}

                            {state.cardStep === 1 && (
                                <>

                                    <div className={"row mt-4"}>
                                        <div className={"col"}>
                                            <label>Plan Name:</label>
                                            <input className={"form-control"} value={state.planName} placeholder={"e.g 4BR-400SM-A... make it unique so you can easily differentiate in your list"} />


                                        </div>
                                    </div>

                                    <div className={"row mt-4"}>
                                        <div className={"col"}>
                                            <label>House Type:</label>

                                            <select value={state.houseType} className={"form-control"} onChange={(e)=>{
                                                dispatch({type: "SET_HOUSE_TYPE", payload: e.currentTarget.value})
                                            }} >
                                                <option value={"residential"}>Private Residential</option>
                                                <option value={"commercial"}>Commercial Residential</option>
                                                <option value={"industrial"}>Industrial</option>
                                            </select>

                                        </div>
                                        <div className={"col"}>
                                            <label>Plan Floors:</label>

                                            <select value={state.planFloors} className={"form-control"} onChange={(e)=>{
                                                dispatch({type: "SET_PLAN_FLOORS", payload: e.currentTarget.value})
                                            }}>
                                                {state.houseType === "residential" && (
                                                    <>
                                                        <option value={0}>0</option>
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                    </>
                                                )}

                                                {state.houseType === "commercial" && (
                                                    <>
                                                        <option value={0}>0</option>
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                        <option value={6}>6</option>
                                                        <option value={7}>7</option>
                                                        <option value={8}>8</option>
                                                        <option value={9}>9</option>
                                                        <option value={10}>10</option>
                                                        <option value={11}>11</option>
                                                        <option value={12}>12</option>
                                                        <option value={13}>13</option>
                                                        <option value={14}>14</option>
                                                        <option value={15}>15</option>
                                                        <option value={16}>16</option>
                                                        <option value={17}>17</option>
                                                        <option value={18}>18</option>
                                                        <option value={19}>19</option>
                                                        <option value={20}>20</option>
                                                        <option value={21}>21</option>
                                                        <option value={22}>22</option>
                                                        <option value={23}>23</option>
                                                        <option value={24}>24</option>
                                                        <option value={25}>25</option>
                                                    </>
                                                )}

                                                {state.houseType === "industrial" && (
                                                    <>
                                                        <option value={0}>0</option>
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                        <option value={6}>6</option>
                                                        <option value={7}>7</option>
                                                        <option value={8}>8</option>
                                                        <option value={9}>9</option>
                                                        <option value={10}>10</option>
                                                        <option value={11}>11</option>
                                                        <option value={12}>12</option>
                                                        <option value={13}>13</option>
                                                        <option value={14}>14</option>
                                                        <option value={15}>15</option>
                                                        <option value={16}>16</option>
                                                        <option value={17}>17</option>
                                                        <option value={18}>18</option>
                                                        <option value={19}>19</option>
                                                        <option value={20}>20</option>
                                                        <option value={21}>21</option>
                                                        <option value={22}>22</option>
                                                        <option value={23}>23</option>
                                                        <option value={24}>24</option>
                                                        <option value={25}>25</option>
                                                        <option value={26}>Above 25</option>
                                                        <option value={27}>Above 30</option>
                                                        <option value={28}>Above 40</option>
                                                        <option value={29}>Above 50</option>
                                                        <option value={30}>Above 60</option>
                                                    </>
                                                )}
                                            </select>

                                        </div>
                                    </div>

                                    <div className={"row mt-4"}>
                                        <div className={"col"}>
                                            <label>Foundation Type:</label>

                                            <select className={"form-control"} value={state.foundation} onChange={(e)=>{
                                                dispatch({type: "SET_FOUNDATION", payload: e.currentTarget.value})
                                            }}>
                                                <option value={"suspended"}>Suspended</option>
                                                <option value={"traditional"}>Traditional</option>
                                            </select>
                                        </div>

                                        <div className={"col"}>
                                            <label>Roof Type:</label>

                                            <select className={"form-control"} value={state.roofType} onChange={(e)=>{
                                                dispatch({type: "SET_ROOF_TYPE", payload: e.currentTarget.value})
                                            }}>
                                                <option value={"concrete"}>Concrete</option>
                                                <option value={"traditional"}>Traditional</option>
                                                <option value={"traditionalFlat"}>Traditional Flat Roof</option>
                                            </select>
                                        </div>
                                    </div>

                                </>
                            )}

                            {state.cardStep === 2 && (
                                <>
                                    {RenderFloors}
                                </>
                            )}

                        </div>


                        <div className={"card-footer"}>

                            {state.cardStep === 0 && (
                                <>
                                    <div className={"row mt-2 mb-2"}>
                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                dispatch({type: "SET_CARD_STEP", payload: 1})
                                            }}>Alright! take me there!</button>
                                        </div>

                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={"btn btn-danger"}>I will do it later!</button>
                                        </div>
                                    </div>
                                </>
                            )}

                            {state.cardStep === 1 && (
                                <>
                                    <div className={"row mt-2 mb-2"}>
                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                dispatch({type: "SET_CARD_STEP", payload: 2})
                                            }}>Next Step</button>
                                        </div>

                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                dispatch({type: "SET_CARD_STEP", payload: state.cardStep - 1})
                                            }}>Previous Step</button>
                                        </div>

                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={"btn btn-danger"}>I will finish later!</button>
                                        </div>
                                    </div>

                                </>
                            )}

                            {state.cardStep === 2 && (
                                <>
                                    <div className={"row mt-2 mb-2"}>
                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                dispatch({type: "SET_CARD_STEP", payload: 3})
                                            }}>Next Step</button>
                                        </div>

                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                dispatch({type: "SET_CARD_STEP", payload: state.cardStep - 1})
                                            }}>Previous Step</button>
                                        </div>

                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={"btn btn-danger"}>I will finish later!</button>
                                        </div>
                                    </div>
                                </>
                            )}


                        </div>

                    </div>

                </div>


            </div>

        </>
    )
}

export default CreatePlan