import React from "react";


const MaterialSearch: React.FC = () => {

    return (
        <></>
    )
}

export  default MaterialSearch