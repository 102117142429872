import React, { useState } from "react";

const FileDownloader = () => {
    const [ftpLink, setFtpLink] = useState("");

    const handleDownload = () => {
        fetch(ftpLink)
            .then((response) => response.blob())
            .then((blob) => {
                // Create a temporary URL for the downloaded file
                const url = URL.createObjectURL(blob);
                // Create a link element
                const link = document.createElement("a");
                // Set the link's href to the temporary URL
                link.href = url;
                // Set the link's download attribute to the desired file name
                link.download = "manual.pdf";
                // Programmatically click the link to start the download
                link.click();
                // Clean up the temporary URL
                URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error downloading file:", error);
            });
    };

    return (
        <div>
            <input
                type="text"
                value={ftpLink}
                onChange={(e) => setFtpLink(e.target.value)}
            />
            <button onClick={handleDownload}>Download</button>
        </div>
    );
};

export default FileDownloader;