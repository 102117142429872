import React from "react";
import PlanHeader from "./PlanHeader";
import PlanMenu from "./PlanMenu";
import PlanFooter from "./PlanFooter";

const PlanHelpSupport: React.FC = () => {

    return (
        <>
            <PlanHeader />
            <PlanMenu />

            <div className={"main-content"}>

                <div className={"page-content"}>


                </div>

                <PlanFooter />
            </div>

        </>
    )
}

export default PlanHelpSupport