import React, {useEffect} from "react";
import {useBreadcrumbs} from "../../Utils";
import ProHeader from "./ProHeader";
import SideMenuPros from "./SideMenu";
import ProStatSummaries from "./ProStatSummaries";
import ArtisanFooter from "../../artisans/dashboard/ArtisanFooter";
import {Link, useNavigate} from "react-router-dom";
import  localForage from "localforage";

const ProfessionalDashboard: React.FC = () => {

    const navigate = useNavigate()

    const checkToken = async () => {
        const token = await localForage.getItem('token');

        if (!token) {
            navigate('/pros/login');
        }
    };

    useEffect(()=>{

        checkToken()

    }, [])

    return (
        <>
            <ProHeader />
            <SideMenuPros/>

            <div className={"vertical-overlay"}> </div>
            <div className={"main-content"}>
                <div className={"page-content"}>

                    {useBreadcrumbs("Dashboard", "Home", "Start")}

                    <ProStatSummaries />

                    <div className={"row mt-4"}>
                        <div className={"col"}>

                            <h6>
                                <i className="bx bx-reset"> </i>
                                &nbsp;
                                App Shortcuts
                            </h6>

                        </div>
                    </div>

                    <div className={"container-fluid"}>

                        <div className={"row mt-4"}>
                            <div className={"col-md-2"}>
                                <div className={"card card-animate"}>
                                    <Link to={"/pros/dashboard/projects"} className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/projects.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Projects</h5>
                                    </Link>
                                </div>
                            </div>

                            <div className={"col-md-2"}>
                                <div className={"card card-animate"}>
                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/teams.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Teams</h5>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-2"}>
                                <div className={"card card-animate"}>
                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/messaging.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Messaging</h5>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-2"}>
                                <div className={"card card-animate"}>
                                    <Link to={"/pros/dashboard/catalog"}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/catalog.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">My Catalog</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div className={"col-md-2"}>
                                <div className={"card card-animate"}>
                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/users/avatar.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Profile</h5>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-2"}>
                                <div className={"card card-animate"}>
                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/jobs.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Jobs</h5>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>

                <ArtisanFooter />
            </div>
        </>
    )
}

export default ProfessionalDashboard