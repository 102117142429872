import React from "react";


const MaterialLogin: React.FC = () => {

    return (
        <>
        </>
    )
}

export default MaterialLogin