import React, {useEffect, useReducer} from 'react';
import {useAppName, useBaseUrl, usePostData, useSentenceCase} from "../Utils";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import * as localForage from "localforage";
import { useNavigate } from 'react-router-dom';

const ProsLogin: React.FC = () =>{

    const navigate = useNavigate()

    const base_url = useBaseUrl()

    const app_name = useAppName()

    const initialState = {
        step: 0,
        subStep: 0,
        pageTitle: "Professionals Login",
        loginType: "phone",
        phone: "",
        email: "",
        password: ""
    }

    const reducer = (state: any, action: any) => {

        switch (action.type){

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            case "SET_LOGIN_TYPE":
                return {...state, loginType: action.payload}

            case "SET_PHONE":
                return {...state, phone: action.payload}

            case "SET_PAGE_TITLE":
                return {...state, pageTitle: action.payload}

            case "SET_EMAIL":
                return {...state, email: action.payload}

            case "SET_PASSWORD":
                return {...state, password: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const becauseReactJSIsStupid = useSentenceCase(state.loginType)

    useEffect(()=>{

        localForage.clear()

        document.title = "Professionals' Login" + app_name
    })

    const UserLogin = async () => {

        if(state.phone === "" && state.email === ""){

            await Swal.fire({
                icon: "info",
                title: "Missing Information",
                text: "Please provide an email or phone number to proceed"
            })

        }

        const formData = new FormData()

        formData.append("loginType", state.loginType)

        if (state.loginType === "phone"){

            formData.append("phone", state.phone)

        }else{

            formData.append("email", state.email)

        }

        formData.append("password", state.password)

        let url = `${useBaseUrl()}/pros/login`

        const resp = await usePostData(url, formData)

        if (resp === undefined){

            console.log("roll roll roll roll roll roll!!!!!!!!!")

            return

        }


        await localForage.setItem("fname", resp.fname)
        await localForage.setItem("lname", resp.lname)
        await localForage.setItem("user_id", resp.user_id)
        await localForage.setItem("token", resp.token)
        await localForage.setItem("avatar", resp.avatar)
        await localForage.setItem("category", resp.category_id)

        await Swal.fire({
            icon: "success",
            title: "Logged In",
            text: "You have successfully logged in let's now head to the dashboard"
        })

        navigate("/pros/dashboard")
    }

    return (
        <>
            <div className={"row mt-4"}>

            </div>

            <div className={"row mt-4"}>

            </div>
            <div className={"row mt-4"}>

                <div className={"col text-center"}>

                    <h3>{state.pageTitle}</h3>

                </div>

            </div>

            <div className={"row mt-4"}>

                <div className={"col-md-3"}>

                </div>

                <div className={"col-md-6"}>

                    <div className={"card card-animate"}>

                        <div className={"card-header"}>

                            {state.step ===0 && (
                                <>
                                    {state.subStep === 0 && (
                                        <>
                                            <h5>Welcome Professional!</h5>
                                        </>
                                    )}

                                    {state.subStep === 1 && (
                                        <>
                                            <h6>Professional Login: {becauseReactJSIsStupid} Login</h6>
                                        </>
                                    )}
                                </>
                            )}


                        </div>

                        <div className={"card-body"}>

                            {state.step === 0 && (

                                <>

                                    {state.subStep === 0 && (
                                        <>
                                            <div className={"row mt-4"}>

                                                <div className="col">

                                            <span>

                                                <p><em style={{fontWeight: "Bold"}}>Welcome to Builders' Search Engine! </em></p>
                                                <p><em>This login page will allow you to access the wide array of tools we've created to make the work of professionals like you easier and more efficient.</em></p>
                                                <p><em>Let's get going! This is the login page...</em></p>



                                            </span>

                                                    <div className={"row mt-4"}>

                                                        <div className={"col"}>
                                                            <button onClick={(e)=>{
                                                                dispatch({type: "SET_SUB_STEP", payload: 1})
                                                            }} style={{width: "100%"}} className={"btn btn-primary"}>
                                                                I have an account
                                                            </button>
                                                        </div>

                                                        <div className={"col"}>
                                                            <Link to={"/artisans/register"} style={{width: "100%"}} className={"btn btn-primary"}>
                                                                I want to register
                                                            </Link>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </>
                                    )}

                                    {state.subStep === 1 && (
                                        <>

                                            <div className={"row mt-4"}>
                                                <div className={"col"}>
                                                    <span>
                                                        <p><em>Select Your Login Mode</em></p>


                                                    </span>

                                                    <span>

                                                    </span>
                                                </div>
                                            </div>

                                            <div className={"row mt-4"}>
                                                <div className={"col"}>
                                                    <input type={"radio"} onChange={(e)=>{
                                                        dispatch({type: "SET_LOGIN_TYPE", payload: "phone"})
                                                    }} checked={state.loginType==="phone"} />
                                                    &nbsp;<label>Use Phone Number</label>
                                                </div>

                                                <div className={"col"}>
                                                    <input type={"radio"} onChange={(e)=>{
                                                        dispatch({type: "SET_LOGIN_TYPE", payload: "email"})
                                                    }} checked={state.loginType==="email"} />
                                                    &nbsp;<label>Use Email</label>
                                                </div>
                                            </div>

                                            <div className={"row mt-2"}>
                                                <div className={"col"}>
                                                    {state.loginType==="phone" && (
                                                        <>

                                                            <label className={"mt-2"}><i className={"bx bx-phone"}> </i>Registered Phone Number</label>
                                                            <input type={"phone"} onChange={(e)=>{
                                                                dispatch({type: "SET_PHONE", payload: e.currentTarget.value})
                                                            }} placeholder={"0712-XXX-XXX"} className={"form-control mt-1"}/>

                                                            <label className={"mt-4"}><i className={"bx bx-key"}> </i>Password</label>
                                                            <input type={"password"} className={"mt-1 form-control"} onChange={(e)=>{
                                                                dispatch({type: "SET_PASSWORD", payload: e.currentTarget.value})
                                                            }} placeholder={"password"}/>
                                                        </>
                                                    )}

                                                    {state.loginType==="email" && (
                                                        <>

                                                            <label className={"mt-2"}><i className={"bx bx-at"}> </i>Registered Email Address</label>
                                                            <input type={"email"} onChange={(e)=>{
                                                                dispatch({type: "SET_EMAIL", payload: e.currentTarget.value})
                                                            }} placeholder={"user@domain"} className={"form-control mt-1"}/>

                                                            <label className={"mt-4"}><i className={"bx bx-key"}> </i>Password</label>
                                                            <input type={"password"} className={"mt-1 form-control"} onChange={(e)=>{
                                                                dispatch({type: "SET_PASSWORD", payload: e.currentTarget.value})
                                                            }} placeholder={"password"}/>
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                        </>
                                    )}

                                </>

                            )}


                        </div>

                        <div className={"card-footer"}>

                            {state.step === 0 && state.subStep==1 && (
                                <>
                                    <div className={"row mt-1 mb-1"}>
                                        <div className={"col"}>
                                            <button onClick={(e)=>{
                                                UserLogin()
                                            }} className={"btn btn-primary"} style={{width: "100%"}}>Confirm Login</button>
                                        </div>

                                        <div className={"col"}>
                                            <Link to={"/pros"} className={"btn btn-primary"} style={{width: "100%"}}>Cancel Login</Link>
                                        </div>
                                    </div>
                                </>
                            )}


                        </div>

                    </div>

                </div>

                <div className={"col-md-3"}>

                </div>

            </div>
        </>
    )
}

export default ProsLogin