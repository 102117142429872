import React from "react";

const HousePlanNav: React.FC = () => {

    return(
        <>



        </>
    )
}

export default HousePlanNav