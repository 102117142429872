import React from "react";


const ArtisanStatSummaries: React.FC = () => {

    return (
        <>
            <div className="row">
                <div className="col-xl-3 col-md-6">
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0"> My
                                        Earnings</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <h5 className="text-success fs-14 mb-0">
                                        <i className="ri-arrow-right-up-line fs-13 align-middle"> </i> +16.24 %
                                    </h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                        Ksh.
                                        <span className="counter-value" data-target="559.25">559.25</span>
                                        k
                                    </h4>
                                    <a href="" className="text-decoration-underline">View net earnings</a>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-success-subtle rounded fs-3">
                                        <i className="bx bx-dollar-circle text-success"> </i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6">
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Invites</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <h5 className="text-danger fs-14 mb-0">
                                        <i className="ri-arrow-right-down-line fs-13 align-middle"> </i>
                                        -3.57 %
                                    </h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                        <span className="counter-value" data-target="36894">36,894</span>
                                    </h4>
                                    <a href="" className="text-decoration-underline">View all orders</a>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-info-subtle rounded fs-3">
                                        <i className="bx bx-shopping-bag text-info"> </i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-xl-3 col-md-6">
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">My Clients</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <h5 className="text-success fs-14 mb-0">
                                        <i className="ri-arrow-right-up-line fs-13 align-middle"> </i> +29.08 %
                                    </h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                        <span className="counter-value" data-target="183.35">183.35</span>
                                        M
                                    </h4>
                                    <a href="" className="text-decoration-underline">See details</a>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-warning-subtle rounded fs-3">
                                        <i className="bx bx-user-circle text-warning"> </i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6">
                    <div className="card card-animate">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                        Income Balance
                                    </p>
                                </div>
                                <div className="flex-shrink-0">
                                    <h5 className="text-muted fs-14 mb-0">
                                        +0.00 %
                                    </h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                        Ksh.
                                        <span className="counter-value" data-target="165.89">165.89</span>
                                        k
                                    </h4>
                                    <a href="" className="text-decoration-underline">Withdraw money</a>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-primary-subtle rounded fs-3">
                                        <i className="bx bx-wallet text-primary"> </i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ArtisanStatSummaries