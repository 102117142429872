import React, {useEffect, useReducer} from 'react'
import ArtisanHeader from "./ArtisanHeader";
import SideMenuArtisans from "./SideMenu";
import {useBreadcrumbs, useGreeting} from "../../Utils";
import {Link} from "react-router-dom";
import ArtisanFooter from "./ArtisanFooter";
import localforage from "localforage";

const ArtisanProfile: React.FC = () => {

    const greeting: any = useGreeting()

    const pro_id = 1

    const initialState = {
        step: 0,
        subStep: 0,
        fName: "",
        lName: "",
        bCrumb: "My Profile",
        category: "Uncategorized",
        subHeading: "",
        infoChanged: false,
        sensitiveChange: false
    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case "SET_FNAME":
                return {...state, fName: action.payload}

            case "SET_LNAME":
                return {...state, lName: action.payload}

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            case "SET_CATEGORY":
                return {...state, category: action.payload}

            case "SET_SUB_HEADING":
                return {...state, subHeading: action.payload}

            case "SET_INFO_CHANGED":
                return {...state, infoChanged: action.payload}

            case "SET_SENSITIVE_CHANGE":
                return {...state, sensitiveChange: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(()=>{
        localforage.getItem("fName").then((fnm: any)=>{
            dispatch({type: "SET_FNAME", payload: fnm})
        })

        localforage.getItem("lName").then((lnm: any)=>{
            dispatch({type: "SET_LNAME", payload: lnm})
        })

        localforage.getItem("category").then((catg: any)=>{
            dispatch({type: "SET_CATEGORY", payload: catg})
        })

        document.title = "BSE - My profile " + state.fName
    }, [])

    return (
        <>
            <ArtisanHeader />
            <SideMenuArtisans />
            <div className={"vertical-overlay"} />

            <div className={"main-content"}>
                <div className={"page-content"}>

                    {useBreadcrumbs(`BSE Professional ${state.fName} ${state.lName} - ${state.category}`, "Dashboard", `${state.bCrumb}`)}

                    <div className={"row mt-4"}>
                        <div className={"col text-center"}>

                            <h3>Account Manager</h3>

                        </div>
                    </div>

                    {state.step === 0 && (
                        <>
                            <div className={"row mt-2"}>

                                <div className={"col text-center"}>


                                    <h6>{greeting["greeting"]} {state.fName}</h6>

                                </div>

                            </div>
                        </>
                    )}

                    {state.step === 0 && (
                        <>
                            <div className={"row mt-4"}>
                                <div className={"col"}>
                                    <div onClick={(e)=>{
                                        dispatch({type: "SET_SUB_STEP", payload: 1})
                                    }} className={"card card-animate"}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/info.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Basic Info</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className={"col"}>
                                    <div className={"card card-animate"}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/portfolio.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">My Portfolio</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className={"col"}>
                                    <div className={"card card-animate"}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/documents.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">My Documents</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className={"col"}>
                                    <div className={"card card-animate"}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/rating.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">My ratings</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className={"col"}>
                                    <div className={"card card-animate"}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/password.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Account</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className={"col"}>
                                    <div className={"card card-animate"}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/gallery.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">My Gallery</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {state.subStep === 0 && (
                                <>

                                </>
                            )}

                            {state.subStep ===1 && (
                                <>
                                    <div className={"row mt-4"}>
                                        <div className={"col-md-2"}>

                                        </div>

                                        <div className={"col-md-8"}>

                                            <div className={"card"}>

                                                <div style={{backgroundColor: "#577BC4", color: "white", fontSize: "15px", fontWeight: "Semi-Bold"}} className={"card-header d-flex justify-content-between align-items-center"}>
                                                    <span>Edit Personal Info</span>
                                                    <img style={{maxHeight: "25px"}} onClick={(e)=>{
                                                        dispatch({type: "SET_SUB_STEP", payload: 0})

                                                    }} src={`${process.env.PUBLIC_URL}/assets/images/close.png`} alt=""
                                                         className="img-fluid card-animate"/>

                                                </div>

                                                <div className={"card-body"}>


                                                </div>

                                                <div className={"card-footer"}>

                                                    <div className={"row mt-1 mb-1"}>

                                                        <div className={"col"}>
                                                            <button style={{width: "100%"}} disabled={!state.sensitiveChange && !state.infoChanged} className={"btn btn-primary"}>Update Info</button>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>

                                        </div>

                                        <div className={"col-md-2"}>

                                        </div>

                                    </div>
                                </>
                            )}
                        </>
                    )}




                </div>
                <ArtisanFooter />
            </div>
        </>
    )
}


export default ArtisanProfile