import React, {useEffect, useReducer, useState} from "react";
import {useBaseUrl, usePostData, useAppName, useSentenceCase} from "../Utils";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
//import {useHistory} from 'react-router-dom'

interface Requirements {
    category: any,
    selected: any,
}

const MaterialFind: React.FC<Requirements> = ({category, selected}) => {

    const initialStep = {
        step: 0,
        subStep: 0,
        cardStep: 0,
        pageHeading: "BuilderSE - Material Suppliers",
        subHeading: "We'll help you find vendors, suppliers, material or even find supply requests",
        acceptTerms: false

    }

    const reducer = (state:any, action: any) => {

        switch(action.type){

            case "SET_STEP":
                return{...state, step: action.payload}

            case "SET_TERMS":
                return {...state, acceptTerms: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            case "SET_PAGE_HEADING":
                return {...state, pageHeading: action.payload}

            case "SET_SUB_HEADING":
                return {...state, subHeading: action.payload}

            default:
                return state
        }

    }

    const [state, dispatch] = useReducer(reducer, initialStep)

    const [categories, setCategories] = useState<any[]>([])
    const [counties, setCounties] = useState<any[]>([])
    const [towns, setTowns] = useState<any[]>([])

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [selectedCat, setSelectedCat] = useState<any>()
    const [selectedCounty, setSelectedCounty] = useState<any>()
    const [selectedTown, setSelectedTown] = useState<any>()

    const appName = useAppName()

    const categoryCap = useSentenceCase(category)

    //const history = useHistory()

    useEffect(()=>{

        document.title = appName

        GetCounties()

        GetCategories()

        dispatch({type: "SET_SUB_HEADING", payload: "You're now searching for - Material " + categoryCap})

        dispatch({type: "SET_PAGE_HEADING", payload: "BuilderSE - " + categoryCap})

    }, [selectedCounty, selectedTown])

    const GetMaterial = async (e: any) => {
        e.preventDefault()

    }

    const GetTowns = async (e: any) => {

        e.preventDefault()

        let url = `${useBaseUrl()}/locations/towns`

        const formData = new FormData()

        formData.append("county_code", selectedCounty)

        const towny = await usePostData(url, formData)

        if (typeof towny === 'undefined' || isNaN(towny)){

            await Swal.fire({
                icon: "info",
                title: "Request failed",
                text: "The server response is not consistent. County is undefined"
            })

            return

        }

        setTowns(towny)

    }

    const GetCounties = async () => {

        let url = `${useBaseUrl()}/locations/counties`

        const formData = new FormData()

        formData.append("county_code", selectedCounty)

        const countys = await usePostData(url, formData)

        if(countys !== null && countys !== "undefined"){
            setCounties(countys)
        }

    }

    const GetCategories = async () =>{

        let url = `${useBaseUrl()}/suppliers/categories`

        const formData = new FormData()

        formData.append("main_cat", category)

        const cats = await usePostData(url, formData)

        if(!isNaN(cats) && cats !== "undefined"){
            setCategories(cats)
        }

    }

    return (
        <section>

            {state.step === 0 && (
                <>
                    <div className={"row mt-4 text-center"}>

                        <div className={"col"}>

                            <h3>{state.pageHeading}</h3>

                        </div>

                    </div>

                    <div className={"row mt-4 text-center"}>

                        <div className={"col"}>

                            <h6>{state.subHeading}. Do you want specific material or find {category}'s shops?</h6>

                        </div>

                    </div>

                    <div className={"row mt-4 text-center"}>
                        <div className={"col-md-2"}>

                        </div>

                        <div className={"col-md-8"}>

                            <div className={"row mt-4"}>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate"  onClick={(e)=>{
                                        dispatch({type: "SET_SUB_HEADING", payload: "Here you can now search for specific material."})
                                        dispatch({type: "SET_STEP", payload: 2})
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/search.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Specific Material</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate" onClick={(e)=>{
                                        dispatch({type: "SET_STEP", payload: 1})
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/suppliers.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Supplier Shop</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate" >
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/bot.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Help Me Decide</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-3"}>
                                    <Link to={"/material/home"} className="card card-animate" >
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/back.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Go back</h5>
                                        </div>
                                    </Link>
                                </div>



                            </div>

                        </div>

                        <div className={"col-md-2"}>

                        </div>
                    </div>

                </>
            )}

            {state.step === 1 && (
                <>
                    <div className={"row mt-4"}>
                        <div className={"col-md-1"}>

                        </div>

                        <div className={"col-md-10"}>

                            <div className="card">
                                <div className="card-header border-0 rounded">
                                    <div className="row g-2">
                                        <div className="col-xl-3">
                                            <div className="search-box">
                                                <input type="text" className="form-control search" placeholder="Type something"/>
                                                <i className="ri-search-line search-icon"> </i>
                                            </div>
                                        </div>

                                        <div className="col ms-auto">
                                            <div>
                                                <select className="form-control" value={selectedCat}
                                                        onChange={
                                                            (e) => {
                                                                setSelectedCat(e.currentTarget.value);
                                                                GetMaterial(e)
                                                            }}>

                                                    <option value={""}>All</option>

                                                    {Object.values(categories).map((cats: any) => (
                                                        <option value={cats.id}>
                                                            {cats.cat}
                                                        </option>
                                                    ))}


                                                </select>
                                            </div>
                                        </div>
                                        <div className={"col ms-auto"}>
                                            <select className="form-control" value={selectedCounty}
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                        const selectedCountyId = e.currentTarget.value;
                                                        setSelectedCounty(selectedCountyId)
                                                        GetTowns(e)
                                                        GetMaterial(e)
                                                    }}
                                            >
                                                <option value={""}>All Counties</option>
                                                {
                                                    selectedCounty != "all" && (
                                                        <>
                                                            {Object.values(counties).map((county: any) => (
                                                                <>
                                                                    <option
                                                                        value={county.county_code}>{county.county}</option>
                                                                </>
                                                            ))}
                                                        </>
                                                    )
                                                }

                                            </select>
                                        </div>
                                        <div className={"col ms-auto"}>

                                            <select className="form-control" disabled={false}
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                        const selectedTown = parseInt(e.currentTarget.value);
                                                        GetMaterial(e)
                                                    }}>
                                                <option value={""}>All Towns</option>
                                                {
                                                    Object.values(towns).map((town: any) => (
                                                        <option value={town.id}> {town.name}
                                                        </option>
                                                    ))
                                                }

                                            </select>
                                        </div>

                                        <div className="col-lg-auto">
                                            <div className="hstack gap-2">
                                                <button type="button" className="btn btn-danger"><i
                                                    className="ri-equalizer-fill me-1 align-bottom"> </i> Filters
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className={"col-md-1"}>

                        </div>
                    </div>
                </>
            )}

            {state.step === 2 && (
                <>
                    <div className={"row mt-4 text-center"}>

                        <div className={"col"}>

                            <h3>{state.pageHeading}</h3>

                        </div>

                    </div>

                    <div className={"row mt-4 text-center"}>

                        <div className={"col"}>

                            <h6>{state.subHeading}</h6>

                        </div>

                    </div>
                    <div className={"row mt-4"}>

                        <div className={"col-md-1"}>


                        </div>

                        <div className={"col-md-10"}>

                            {state.subStep === 0 && (
                                <>
                                    <div className={"row mt-2"}>
                                        <div className={"col-md-2"}>

                                        </div>

                                        <div className={"col-md-8"}>

                                            <div className={"card"}>
                                                <div style={{backgroundColor: "#577BC4", color: "white", fontSize: "15px", fontWeight: "Bold"}} className={"card-header d-flex justify-content-between align-items-center"}>
                                                    <span> Initialize...</span>
                                                    <img style={{maxHeight: "25px"}} onClick={(e)=>{

                                                        dispatch({type: "SET_STEP", payload: 0})

                                                    }} src={`${process.env.PUBLIC_URL}/assets/images/close.png`} alt=""
                                                         className="img-fluid card-animate"/>

                                                </div>

                                                <div className={"card-body"}>

                                                    <p>Howdy!</p>
                                                    <p>This step is necessary for us.</p>
                                                    <p>welcome to our {category} search mini-app.</p>
                                                    <p>You can press the "X" or close button to exit at any time.</p>
                                                    <p>Alright! Let's get going!</p>

                                                    <p>
                                                        <input onChange={(e)=>{
                                                            dispatch({type:"SET_TERMS", payload: !state.acceptTerms})
                                                        }} type={"checkbox"} checked={state.acceptTerms}/>
                                                        <label><em> &nbsp; &nbsp;I understand</em></label>
                                                    </p>

                                                </div>

                                                <div className={"card-footer"}>

                                                    <div className={"row mt-1 mb-1"}>
                                                        <div className={"col"}>

                                                            <button style={{width: "100%"}} onClick={(e)=>{

                                                                dispatch({type: "SET_SUB_STEP", payload: 1})

                                                            }} disabled={!state.acceptTerms} className={"btn btn-primary"}>
                                                                I want to proceed
                                                            </button>
                                                        </div>

                                                        <div className={"col"}>

                                                            <button style={{width: "100%"}} className={"btn btn-primary"}>
                                                                Maybe Later!
                                                            </button>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                        <div className={"col-md-2"}>

                                        </div>
                                    </div>
                                </>
                            )}

                            {state.subStep===1 && (
                                <>
                                    <div className={"row mt-2"}>
                                        <div className={"col"}>

                                            <div className={"card"}>
                                                <div style={{backgroundColor: "#577BC4", color: "white", fontSize: "15px", fontWeight: "Bold"}} className={"card-header d-flex justify-content-between align-items-center"}>
                                                    <span>Step 1: </span>
                                                    <img style={{maxHeight: "25px"}} onClick={(e)=>{

                                                        dispatch({type: "SET_STEP", payload: 0})

                                                    }} src={`${process.env.PUBLIC_URL}/assets/images/close.png`} alt=""
                                                         className="img-fluid card-animate"/>

                                                </div>

                                                <div className={"card-body"}>

                                                    {state.cardStep === 0 && (
                                                        <>
                                                            <div className={"row mt-4"}>

                                                                <div className={"col"}>

                                                                    <h6>Please select from the following categories where applicable:</h6>

                                                                    <div className={"row mt-3 mb-1"}>


                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </>
                                                    )}


                                                </div>

                                                <div className={"card-footer"}>

                                                    <div className={"row mt-1 mb-1"}>
                                                        <div className={"col"}>
                                                            <button style={{width: "100%"}} className={"btn btn-primary"}>Next Step</button>
                                                        </div>

                                                        <div className={"col"}>
                                                            <button style={{width: "100%"}} className={"btn btn-primary"}>Cancel</button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </>
                            )}

                        </div>

                        <div className={"col-md-1"}>


                        </div>

                    </div>
                </>
            )}

        </section>
    )
}

export default MaterialFind
