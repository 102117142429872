import React from "react";

const ArtisanFooter: React.FC = () => {


    let currentYear;
    return(
        <>
            <footer className="footerrs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-4">
                            <script type="text/javascript">{`document.write(${currentYear})`}</script>
                            © BuilderSE.
                        </div>
                        <div className="col-sm-8">
                            <div className="text-sm-end d-none d-sm-block">
                                Dev::: auto_guest():zoner?:True ::: Python detected - v3.10, Django detected - v4.1.7 , ReactJS detected - v18.2.0
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default ArtisanFooter