import React from 'react'
import PlanFooter from "./PlanFooter";
import PlanHeader from "./PlanHeader";
import PlanMenu from "./PlanMenu";

const PlanDashboard: React.FC = () => {

    return (
        <>
            <PlanHeader />

            <PlanMenu />

            <div className={"main-content"}>

                <div className={"page-content"}>


                </div>

                <PlanFooter />
            </div>

        </>
    )


}

export default PlanDashboard