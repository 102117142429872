import React, {useEffect, useState} from "react";


const MaterialRegister: React.FC = () => {

    const [step, setStep] = useState(1)

    const [storeName, setStoreName] = useState("")

    useEffect(()=>{
        document.title= "Register Vendors & suppliers"
    }, [])

    return (
        <>

            <div className={"container-fluid"}>

                <div className={"row mt-4"}>

                    <div className={"col-md-1"}>

                    </div>

                    <div className={"col-md-10"}>

                        {step === 1 && (
                            <>
                                <div className={"card card-animate"}>

                                    <div className={"card-header"}>

                                        Choose Category

                                    </div>

                                    <div className={"card-body"}>

                                        <div className={"row mt-4"}>

                                            <div className={"col-md-4"}>
                                                <label>
                                                    <i className="bx bx-chip"> </i>
                                                   &nbsp; Store Name
                                                </label>

                                                <input className={"mt-2 form-control"} type={"text"}/>


                                            </div>

                                            <div className={"col-md-4"}>
                                                <label>
                                                    <i className="bx bx-phone"> </i>
                                                    &nbsp; Store Phone Number
                                                </label>

                                                <input className={"mt-2 form-control"} type={"phone"}/>


                                            </div>

                                            <div className={"col-md-4"}>
                                                <label>
                                                    <i className="bx bx-at"> </i>
                                                    &nbsp; Store Email
                                                </label>

                                                <input className={"mt-2 form-control"} type={"email"}/>
                                            </div>
                                        </div>

                                        <div className={"row mt-4"}>
                                            <div className={"col"}>
                                                <label>
                                                    <i className="bx bx-location-plus"> </i>
                                                    &nbsp; County
                                                </label>

                                                <select className={"form-control"}>
                                                    <option>Choose One</option>

                                                </select>
                                            </div>

                                            <div className={"col"}>
                                                <label>
                                                    <i className="bx bx-location-plus"> </i>
                                                    &nbsp; Town
                                                </label>

                                                <select className={"form-control"}>
                                                    <option>Choose One</option>

                                                </select>
                                            </div>
                                        </div>

                                        <div className={"row mt-4"}>
                                            <div className={"col"}>
                                                <label>
                                                    <i className="bx bx-location-plus"> </i>
                                                    &nbsp; Store Latitude - <em>Optional</em> &nbsp; &nbsp;
                                                    <button className={"btn btn-success"}>Get</button>
                                                </label>

                                                <input className={"mt-2 form-control"} disabled={true} type={"text"}/>
                                            </div>

                                            <div className={"col"}>
                                                <label>
                                                    <i className="bx bx-location-plus"> </i>
                                                    &nbsp; Store Longitude - <em>Optional</em> &nbsp; &nbsp;
                                                    <button className={"btn btn-success"}>Get</button>
                                                </label>

                                                <input className={"mt-2 form-control"} disabled={true} type={"text"}/>
                                            </div>
                                        </div>

                                        <div className={"row mt-4"}>

                                            <div className={"row mt-4"}>
                                                <div className={"col"}>
                                                    <label>
                                                        <i className="bx bx-location-plus"> </i>
                                                        &nbsp; Store Latitude - <em>Optional</em>
                                                    </label>

                                                    <input className={"mt-2 form-control"} disabled={true} type={"text"}/>
                                                </div>

                                                <div className={"col"}>
                                                    <label>
                                                        <i className="bx bx-location-plus"> </i>
                                                        &nbsp; Store Longitude - <em>Optional</em> &nbsp; &nbsp;
                                                        <button className={"btn btn-success"}>Get</button>
                                                    </label>

                                                    <input className={"mt-2 form-control"} disabled={true} type={"text"}/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </>
                        )}

                    </div>

                    <div className={"col-md-1"}>

                    </div>

                </div>
            </div>

        </>
    )
}

export default MaterialRegister