import React, {useState, useReducer, useEffect} from 'react'
import {Link} from "react-router-dom";

const JoinTeam: React.FC = () => {

    const initialState = {
        step: 0,
        subStep: 0,
        pageHeading: "Join Builder Teams",
        selectedCode: "TEXT",
        inviteCode: "",
        qrCode: null
    }

    const reducer = (state: any, action: any) =>{

        switch (action.type){
            case 'SET_STEP':
                return {...state, step: action.payload}

            case 'SET_SUB_STEP':
                return {...state, subStep: action.payload}

            case 'SET_PAGE_HEADING':
                return {...state, pageHeading: action.payload}

            case 'SET_USE_QR':
                return {...state, selectedCode: action.payload}

            case 'SET_USE_TEXT':
                return {...state, selectedCode: action.payload}

            case 'SET_INVITE_CODE':
                return {...state, inviteCode: action.payload}

            case 'SET_INVITE_QR':
                return {...state, qrCode: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const divStyle = {borderRadius: "3%"}

    return (
        <>
            <div className={"container-fluid"} style={{fontSize: "14px"}}>

                <div className={"row mt-4"}>

                    <div className={"col"}>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4 text-center"}> <h3>{state.pageHeading}</h3> </div>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4"}> </div>

                        {state.step === 0 && (
                            <>
                                <div className={"row mt-4"}>

                                    <div className={"col-md-3"}>

                                    </div>

                                    <div className={"col-md-6"}>

                                        <div className={"row mt-3"}>

                                            <div className={"col-md-6"}>
                                                <div className="card card-animate" style={divStyle}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/joincode.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Join By Code</h5>

                                                        <p><button style={{width: "80%"}} onClick={(e)=>{
                                                            dispatch({type: "SET_STEP", payload: 1})
                                                        }} className="btn btn-primary  btn-lg mt-4">Use Invite Code</button></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col-md-6"}>
                                                <div className="card card-animate" style={divStyle}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/find.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Find Open Teams</h5>

                                                        <p><button onClick={(e)=>{
                                                            dispatch({type: "SET_STEP", payload: 2})
                                                        }} style={{width: "80%"}} className="btn btn-primary  btn-lg mt-4">Find Teams</button></p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className={"col-md-3"}>

                                    </div>

                                </div>

                            </>
                        )}

                        {state.step === 1 && (
                            <>
                                <div className={"row mt-4"}>
                                    <div className={"col-md-3"}>


                                    </div>

                                    <div className={"col-md-6"}>


                                        <div className={"container-fluid"}>

                                            <div className={"card"}>

                                                <div className={"card-header"} style={{backgroundColor: "#27AEEE", color: "white"}}>

                                                    Join By Invitation

                                                </div>

                                                <div className={"card-body"}>

                                                    <div className={"row mt-3"}>

                                                        <div className={"col"}>

                                                            <input type={"radio"} onChange={(e)=>{
                                                                dispatch({type: "SET_USE_QR", payload: "QR"})
                                                            }} checked={state.selectedCode === "QR"} />

                                                            &nbsp;  &nbsp;

                                                            <label>Submit QR Code</label>

                                                        </div>

                                                        <div className={"col"}>

                                                            <input type={"radio"} onChange={(e)=>{
                                                                dispatch({type: "SET_USE_TEXT", payload: "TEXT"})
                                                            }} checked={state.selectedCode === "TEXT"} />

                                                            &nbsp;  &nbsp;

                                                            <label>Submit Text Code</label>

                                                        </div>

                                                    </div>

                                                    <div className={"row mt-3"}>

                                                        <div className={"col"}>

                                                            {state.selectedCode === "QR" && (
                                                                <>
                                                                    <i className={"bx bx-qr-scan"}> </i>  &nbsp;
                                                                    <label style={{fontWeight: "Bold", fontSize: "16px"}}>Upload QR File </label>

                                                                    <input className={"form-control mt-2"} type={"file"} onChange={(e)=>{
                                                                        const selectedFile = e.currentTarget.files?.[0];

                                                                        dispatch({type: "SET_INVITE_QR", payload: selectedFile})
                                                                    }}/>
                                                                </>
                                                            )}

                                                            {state.selectedCode === "TEXT" && (
                                                                <>

                                                                    <label style={{fontWeight: "Bold", fontSize: "16px"}}>
                                                                        <i className="bx bx-note"> </i>  &nbsp;
                                                                        Enter Code
                                                                    </label>

                                                                    <input type={"text"} placeholder={"BSE-IVT-XXXX-XXX-XXX-XXX-XXX-CRYPTSE"} className={"form-control mt-2"} value={state.inviteCode} onChange={(e)=>{
                                                                        dispatch({type: "SET_INVITE_CODE", payload: e.currentTarget.value})
                                                                    }} />


                                                                </>
                                                            )}

                                                        </div>

                                                    </div>

                                                </div>

                                                <div className={"card-footer"}>
                                                    {state.step===1 && state.selectedCode==="TEXT" && (
                                                        <>
                                                            <div className={"row mt-1 mb-1"}>
                                                                <div className={"col"}>

                                                                    <button style={{width: "100%"}} className={"btn btn-primary"}>
                                                                        Validate Code
                                                                    </button>

                                                                </div>

                                                                <div className={"col"}>
                                                                    <button style={{width: "100%"}} onClick={(e)=>{
                                                                        dispatch({type: "SET_INVITE_CODE", payload: ""})
                                                                    }} className={"btn btn-primary"}>
                                                                        Clear Code
                                                                    </button>

                                                                </div>

                                                                <div className={"col"}>

                                                                    <Link to={"/teams"} style={{width: "100%"}} className={"btn btn-primary"}>
                                                                        Cancel Process
                                                                    </Link>

                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    {state.step===1 && state.selectedCode==="QR" && (
                                                        <>
                                                            <div className={"row mt-1 mb-1"}>
                                                                <div className={"col"}>

                                                                    <button style={{width: "100%"}} className={"btn btn-primary"}>
                                                                        Upload QR File
                                                                    </button>

                                                                </div>

                                                                <div className={"col"}>
                                                                    <button style={{width: "100%"}} onClick={(e)=>{
                                                                        dispatch({type: "SET_INVITE_QR", payload: null})
                                                                    }} className={"btn btn-primary"}>
                                                                        Clear Code
                                                                    </button>

                                                                </div>

                                                                <div className={"col"}>

                                                                    <Link to={"/teams"} style={{width: "100%"}} className={"btn btn-primary"}>
                                                                        Cancel Process
                                                                    </Link>

                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className={"col-md-3"}>


                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                </div>

            </div>
        </>
    )
}

export default JoinTeam