import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useAppName, useBaseUrl, useGetData, usePostData} from "../Utils";
import Swal from "sweetalert2";


const MaterialStart: React.FC = () => {

    const [step, setStep] = useState(1)
    const [subStep, setSubStep] = useState(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [types, setTypes] = useState<any[]>([])
    const [companies, setCompanies] = useState<any[]>([])


    const [company, setCompany] = useState("")
    const [companyDescr, setCompanyDescr] = useState("")

    const [type, setType] = useState("")
    const [typeDescr, setTypeDescr] = useState("")

    const [selectedCompany, setSelectedCompany] = useState<any>("")
    const [selectedType, setSelectedType] = useState<any>("")
    const [selectedBrand, setSelectedBrand] = useState<any>("")

    const [brand, setBrand] = useState<any>("")
    const [brandDesc, setBrandDesc] = useState<any>()
    const [brandLogo, setBrandLogo] = useState<any>()
    const [brandAbbrv, setBrandAbbrv] = useState<any>()

    const appName = useAppName()

    useEffect(()=> {
        document.title = appName

    }, []);

    const divStyle = {
        borderRadius: "3% "
    }

    const CreateMaterialItems = async  (e: any) =>{
        e.preventDefault()



    }

    const CreateCompany = async (e: any) => {
        e.preventDefault()

        setIsLoading(true)

        let url = `${useBaseUrl()}/suppliers/add-company`

        const formData = new FormData()

        formData.append("company", company)
        formData.append("descr", companyDescr)

        const resp = await usePostData(url, formData).finally(()=>{

                setIsLoading(false)

            }

        )

        if(resp === "added"){
            await Swal.fire({
                icon: "success",
                title: "Added",
                text: company + " added successfully"
            })
        }

    }

    const CreateTypes = async (e: any) => {
        e.preventDefault()

        setIsLoading(true)

        let url = `${useBaseUrl()}/suppliers/add-types`

        const formData = new FormData()

        formData.append("type", type)
        formData.append("descr", typeDescr)

        const resp = await usePostData(url, formData).finally(()=>{

                setIsLoading(false)

            }

        )

        if(resp === "added"){
            await Swal.fire({
                icon: "success",
                title: "Added",
                text: type + " added successfully"
            })
        }

    }

    const GetTypes = async () => {

        let url = `${useBaseUrl()}/suppliers/get-types`

        const typr = await useGetData(url)

        setTypes(typr)

    }

    const GetCompanies = async () => {

        let url = `${useBaseUrl()}/suppliers/get-companies`

        const companr = await useGetData(url)

        setCompanies(companr)

    }

    const CreateBrands = async (e: any) =>{
        e.preventDefault()

        setIsLoading(true)

        let url = `${useBaseUrl()}/suppliers/add-brand`

        const formData = new FormData()

        formData.append("company", selectedCompany)
        formData.append('type', selectedType)
        formData.append("brand", brand)
        formData.append("logo", brandLogo)
        formData.append("abbrv", brandAbbrv)
        formData.append("descr", brandDesc)

        const resp = await usePostData(url, formData).finally(()=>{
            setIsLoading(false)
        })

        if (resp === "added"){

            await Swal.fire({
                icon: "success",
                title: "Added",
                text: type + " added successfully"
            })

        }

        return


    }

    const [title, setTitle] = useState("Material Search")

    return(
        <>
            <div className={"container-fluid"}>
                <div className={"row"}>

                    <div className={"container-fluid"}>

                        <div className={"row"}>

                            <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>

                            <div className={"col-xxl-6 col-xl-6 col-lg-6 col-md-8"}>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4 text-center"}> <h3>Get Started - {title}</h3> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}> </div>

                                {step === 1 && (
                                    <>
                                        <div className={"row mt-4"}>
                                            <div className={"col"}>
                                                <div className="card card-animate" style={divStyle}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/suppliers.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Find Suppliers</h5>

                                                        <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                                 to={"/material/home/suppliers"}>Get Started</Link></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate" style={divStyle}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/vendors.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Find Vendors</h5>

                                                        <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                                 to={"/material/home/vendors"}>Get Started</Link></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate" style={divStyle}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/requests.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Material Requests</h5>

                                                        <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                                 to={"/material/requests"}>Get Started</Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"row mt-4"}>
                                            <div className={"col"}>
                                                <div className="card card-animate" style={divStyle}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/start_login.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Login</h5>

                                                        <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                                 to={"/material/login"}>Login</Link></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate" style={divStyle}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/patient_register.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Create Account</h5>

                                                        <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                                 to={"/material/login"}>Register</Link></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate" style={divStyle} onClick={()=>{
                                                    setStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/suppliers.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Temporary Option</h5>


                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </>
                                )}

                                {step === 2 && (
                                    <>
                                        <div className={"row mt-4"}>

                                            <div className={"col"}>

                                                <div className={"card card-animate"}>

                                                    {subStep === 1 && (
                                                        <>
                                                            <>
                                                                <div className={"card-header"}>
                                                                    Create Material Companies
                                                                </div>

                                                                <div className = {"card-body"}>

                                                                    <form onSubmit={CreateCompany}>

                                                                        <label className={"mt-2"}>Company</label>
                                                                        <input className={"form-control"} value={company} onChange={(e)=>{
                                                                            setCompany(e.currentTarget.value)
                                                                        }} />

                                                                        <label className={"mt-2"}>Description</label>
                                                                        <input className={"form-control"} value={companyDescr} onChange={(e)=>{
                                                                            setCompanyDescr(e.currentTarget.value)
                                                                        }} />

                                                                        <button className={"btn btn-success mt-4"} type={"submit"} style={{width: "100%"}}>Add Company</button>

                                                                    </form>

                                                                    <button className={"btn btn-danger mt-4"} style={{width: "100%"}} onClick={()=>{
                                                                        setSubStep(2)
                                                                    }}>Next: Add Material Types</button>

                                                                </div>
                                                            </>
                                                        </>
                                                    )}

                                                    {subStep === 2 &&(
                                                        <>
                                                            <div className={"card-header"}>
                                                                Add Material Types
                                                            </div>

                                                            <div className = {"card-body"}>

                                                                <form onSubmit={CreateTypes}>

                                                                    <label className={"mt-2"}>Type</label>
                                                                    <input className={"form-control"} value={type} onChange={(e)=>{
                                                                        setType(e.currentTarget.value)
                                                                    }} />

                                                                    <label className={"mt-2"}>Description</label>
                                                                    <input className={"form-control"} value={typeDescr} onChange={(e)=>{
                                                                        setTypeDescr(e.currentTarget.value)
                                                                    }} />

                                                                    <button className={"btn btn-success mt-4"} type={"submit"} style={{width: "100%"}}>Add Type</button>

                                                                </form>

                                                                <button className={"btn btn-danger mt-4"} style={{width: "100%"}} onClick={()=>{
                                                                    GetTypes()
                                                                    GetCompanies()
                                                                    setSubStep(3)
                                                                }}>Next: Add Material Brands</button>
                                                            </div>
                                                        </>

                                                    )}

                                                    {subStep === 3 && (
                                                        <>
                                                            <>
                                                                <div className={"card-header"}>
                                                                    Add Material Brands
                                                                </div>

                                                                <div className = {"card-body"}>

                                                                    <form onSubmit={CreateBrands}>

                                                                        <label className={"form-label mt-3"}>Select Company</label>
                                                                        <select className={"form-control"} value={selectedCompany} onChange={(e)=>{
                                                                            setSelectedCompany(e.currentTarget.value)
                                                                        }} >
                                                                            {Object.values(companies).map((cty: any)=>(
                                                                                <option value={cty.id}> {cty.company} </option>
                                                                            ))}
                                                                        </select>

                                                                        <label className={"form-label mt-3"}>Select Type</label>
                                                                        <select className={"form-control"} value={selectedType} onChange={(e)=>{
                                                                            setSelectedType(e.currentTarget.value)
                                                                        }} >
                                                                            {Object.values(types).map((cty: any)=>(
                                                                                <option value={cty.id}> {cty.type} </option>
                                                                            ))}
                                                                        </select>

                                                                        <label className={"form-label mt-3"}>Brand Name</label>
                                                                        <input className={"form-control"}  value={brand} onChange={(e)=>{
                                                                            setBrand(e.currentTarget.value)
                                                                        }}/>

                                                                        <label className={"form-label mt-3"}>Brand Logo</label>
                                                                        <input className={"form-control"} type={"file"} onChange={(e: any)=>{
                                                                            setBrandLogo(e.currentTarget.files[0])
                                                                        }}/>

                                                                        <label className={"form-label mt-3"}>Brand Abbreviation</label>
                                                                        <input className={"form-control"} value={brandAbbrv} onChange={(e)=>{
                                                                            setBrandAbbrv(e.currentTarget.value)
                                                                        }} />

                                                                        <label className={"form-label mt-3"}>Brand Description</label>
                                                                        <input className={"form-control"} value={brandDesc} onChange={(e)=>{
                                                                            setBrandDesc(e.currentTarget.value)
                                                                        }} />

                                                                        <button className={"btn btn-success mt-4"} type={"submit"} style={{width: "100%"}}>Add Type</button>

                                                                    </form>

                                                                    <button className={"btn btn-danger mt-4"} style={{width: "100%"}} onClick={()=>{

                                                                        setSubStep(4)
                                                                    }}>Next: Add Material Brands</button>
                                                                </div>
                                                            </>
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                )}



                            </div>

                            <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default MaterialStart