import React, {useEffect} from 'react'
import {Link} from "react-router-dom";
import Swal from 'sweetalert2';

const ArtisanHome: React.FC = () => {
    useEffect(()=> {
        document.title = "BuilderSE - Artisans"

    }, []);

    const divStyle = {
        borderRadius: "3% "
    }
    return(
        <>
            <div className={"container-fluid"}>
                <div className={"row"}>

                    <div className={"container-fluid"}>

                        <div className={"row"}>

                            <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>

                            <div className={"col-xxl-6 col-xl-6 col-lg-6 col-md-8"}>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4 text-center"}> <h3>Get Started - Artisans</h3> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>
                                        <div className="card card-animate" style={divStyle}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/search.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Find Artisans</h5>

                                                <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                         to={"/artisans/search"}>Get Started</Link></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col"}>
                                        <div className="card card-animate" style={divStyle}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/jobs.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Find Jobs</h5>

                                                <p><button onClick={(e)=>{
                                                    Swal.fire({
                                                        icon: "success",
                                                        title: "Request Complete",
                                                        text: "There are currently no job listings for this category. Once someone posts a job you will have access."
                                                    })
                                                }} className="btn btn-outline-primary btn-rounded btn-lg mt-4">Show List</button></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>
                                        <div className="card card-animate" style={divStyle}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/start_login.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Login</h5>

                                                <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                         to={"/artisans/login"}>Login</Link></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col"}>
                                        <div className="card card-animate" style={divStyle}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/patient_register.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Create Account</h5>

                                                <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                         to={"/artisans/register"}>Register</Link></p>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default ArtisanHome