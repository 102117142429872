import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";

const Teams: React.FC = () => {

    useEffect(()=>{
        document.title = "BuilderSE - Teams"
    })

    const [pageTitle, setPageTitle] = useState("BuilderSE Collaborative Teams")

    const divStyle = {
        borderRadius: "3% "
    }
    return(
        <>
            <div className={"container-fluid"}>
                <div className={"row"}>

                    <div className={"container-fluid"}>

                        <div className={"row"}>

                            <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>

                            <div className={"col-xxl-6 col-xl-6 col-lg-6 col-md-8"}>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4 text-center"}> <h3>{pageTitle}</h3> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}> </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>
                                        <div className="card card-animate" style={divStyle}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/search.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Find Teams</h5>

                                                <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                         to={"/teams/search"}>Get Started</Link></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col"}>
                                        <div className="card card-animate" style={divStyle}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/teamjob.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Team Jobs</h5>

                                                <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                         to={"/teams/jobs"}>Show List</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>
                                        <div className="card card-animate" style={divStyle}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/teamcreate.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Create Team</h5>

                                                <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                         to={"/teams/create"}>Start Process</Link></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col"}>
                                        <div className="card card-animate" style={divStyle}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/teamlink.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Join Team</h5>

                                                <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                         to={"/teams/join"}>Start Process</Link></p>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default Teams