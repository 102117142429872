import React, {useEffect, useState} from "react";
import HousePlanNav from "./HouseplanNav";
import {useBreadcrumbs} from "../Utils";
import {Link} from "react-router-dom";



const HousePlansHome: React.FC = () =>{

    const [isQuickCollapsed, setIsQuickCollapsed] = useState<boolean>(false)
    const [contextTitle, setContextTitle] = useState("All House Plans")
    const [planCount, setPlanCount] = useState(0)
    const [step, setStep] = useState(1)

    useEffect(()=>{
        document.title = "BSE - HousePlans "
    }, [])

    const headerStyles = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/plan.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        height: `250px`
    };

    const divStyle = {
        borderRadius: "3%"
    }

    return (
        <>
            <div className={"overlay mt-4"}> </div>

            <HousePlanNav />

            <div className={"overlay mt-4"}> </div>

            {useBreadcrumbs("HousePlans", "Get Started", "Search")}

            <div className={"row mt-4"}>
                <div className={"col"}>

                    <div className={"container-fluid"}>
                        <div className={"row-mt-4"}>
                            <div className={"col text-center"}>
                                <h1>

                                    {contextTitle}

                                </h1>
                            </div>
                        </div>

                        <div className={"overlay mt-3"}> </div>

                        <div className={"row mt-4"}>

                            <div className={"col-md-3"}>

                                <div className={"container-fluid"}>
                                    <div className={"row"}>

                                        <div className={"col"}>
                                            <input type={"text"} className={"form-control"} placeholder={"Plan No. "} />
                                        </div>

                                        <div className={"col"}>
                                            <button className={"btn btn-primary"} style={{width: "100%", backgroundColor: "#6599CB"}}>
                                                Search
                                            </button>
                                        </div>

                                    </div>

                                    <div className={"card mt-4"} style={{borderRadius: "3%", backgroundColor: "#6599CB", color: "white"}}>

                                        <div className={"card-header"} style={{backgroundColor: "#6599CB", color: "white", border: "none"}} onClick={(e)=>{
                                            setIsQuickCollapsed(!isQuickCollapsed)
                                        }} >
                                            {isQuickCollapsed && (<i className="bx bx-plus"> </i>)} {!isQuickCollapsed && (<i className="bx bx-minus"> </i>)} Quick search
                                        </div>

                                        {isQuickCollapsed ? null : (

                                            <div className={"card-body"}>

                                                <div className={"container-fluid"}>

                                                    <h6 style={{color: "white"}}>Living Area</h6>

                                                    <div className={"row mt-2"}>
                                                        <div className={"col"}>
                                                            <label>Low SQM</label>
                                                            <input className={"form-control"} type={"number"}/>
                                                        </div>

                                                        <div className={"col"}>
                                                            <label>High SQM</label>
                                                            <input className={"form-control"} type={"number"}/>
                                                        </div>
                                                    </div>

                                                    <h6 className={"mt-2"} style={{color: "white"}}>Bedrooms</h6>

                                                    <div className={"row mt-2"}>
                                                        <div className={"col"}>
                                                            <label>Min Bedrooms</label>
                                                            <input className={"form-control"} type={"number"}/>
                                                        </div>

                                                        <div className={"col"}>
                                                            <label>Max Bedrooms</label>
                                                            <input className={"form-control"} type={"number"}/>
                                                        </div>
                                                    </div>

                                                    <div className={"row mt-2"}>
                                                        <div className={"col"}>
                                                            <label>Storeys</label>
                                                            <select className={"form-control"}>
                                                                <option>1</option>
                                                                <option>2</option>
                                                                <option>3</option>
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div className={"row mt-2"}>
                                                        <div className={"col"}>
                                                            <label>Bathrooms</label>
                                                            <select className={"form-control"}>
                                                                <option>1</option>
                                                                <option>2</option>
                                                                <option>3</option>
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div className={"row mt-2"}>
                                                        <div className={"col"}>
                                                            <label>Garages</label>
                                                            <select className={"form-control"}>
                                                                <option>1</option>
                                                                <option>2</option>
                                                                <option>3</option>
                                                            </select>
                                                        </div>

                                                    </div>


                                                    <div className={"row mt-2"}>
                                                        <div className={"col"}>

                                                            <button style={{color: "white", width: "100%"}} className={"btn btn-outline-info"}>
                                                                Search
                                                            </button>

                                                        </div>
                                                    </div>

                                                    <div className={"row mt-2"}>
                                                        <div className={"col"}>

                                                            <button style={{color: "white", width: "100%"}} className={"btn btn-outline-danger"}>
                                                                Reset
                                                            </button>

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        )

                                        }


                                    </div>

                                    <button className={"btn btn-primary"} style={{backgroundColor: "#6599CB", width: "100%"}}>Save Your Search</button>

                                    <button className={"btn btn-primary mt-4"} style={{backgroundColor: "#6599CB", width: "100%"}}>Sell Your Plans</button>

                                </div>

                            </div>
                            <div className={"col-md-9"}>

                                {step === 1 && (
                                    <div className={"row"}>

                                        <div className={"col-md-4 "}>

                                            <div className={"card card-animate"}>
                                                <div className={"card-header"} style={headerStyles}>



                                                </div>
                                                <div className={"card-content"}>
                                                    <div className={"row mt-3"}>

                                                        <div className={"col"}>

                                                            <div className={"container-fluid"}>

                                                                <h4>Plan 001 - 667B</h4>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className={"row mt-2"} style={{borderBottom: "1px"}}>

                                                        <div className={"col-md-4"}>
                                                            <div className={"container-fluid"}>
                                                                Floors: 1
                                                            </div>
                                                        </div>

                                                        <div className={"col-md-4"}>
                                                            <div className={"container-fluid"}>
                                                                Bedrooms: 2
                                                            </div>
                                                        </div>

                                                        <div className={"col-md-4"}>
                                                            <div className={"container-fluid"}>
                                                                Baths: 3
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className={"row mt-2"} style={{borderBottom: "1px"}}>

                                                        <div className={"col-md-4"}>
                                                            <div className={"container-fluid"}>
                                                                Len: 14m
                                                            </div>
                                                        </div>

                                                        <div className={"col-md-4"}>
                                                            <div className={"container-fluid"}>
                                                                Wid: 22m
                                                            </div>
                                                        </div>

                                                        <div className={"col-md-4"}>
                                                            <div className={"container-fluid"}>
                                                                Area: 400m<sup>2</sup>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className={"row mt-4 mb-3"}>
                                                        <div className={"col"}>

                                                            <div className={"container-fluid"}>

                                                                <Link to={"/houseplans/details"} className={"btn btn-outline-primary"} style={{width: "100%"}}>
                                                                    Load Plan
                                                                </Link>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div className={"col-md-4"}>

                                            <div className={"card card-animate"}>
                                                <div className={"card-header"} style={headerStyles}>



                                                </div>
                                                <div className={"card-content"}>

                                                </div>
                                            </div>

                                        </div>

                                        <div className={"col-md-4"}>

                                            <div className={"card card-animate"}>
                                                <div className={"card-header"} style={headerStyles}>



                                                </div>
                                                <div className={"card-content"}>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                )}


                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default HousePlansHome