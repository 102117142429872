import React, {useState} from 'react'
import ArtisanHeader from "./ArtisanHeader";
import SideMenuArtisans from "./SideMenu";
import ArtisanFooter from "./ArtisanFooter";
import {useBaseUrl, useBreadcrumbs, usePostData} from "../../Utils";
import Swal from "sweetalert2";

const ArtisanCatalog: React.FC = () => {
    const [step, setStep] = useState(1)

    const CreateCatalog = async () => {

        const formData = new FormData()

        let url = `${useBaseUrl()}/artisans/create-catalog`

        const resp = await usePostData(url, formData)

        if(resp === "created"){

            await Swal.fire({
                icon: "success",
                title: "Created",
                text: "Your Catalog was successfully created"
            })

        }

    }

    return (
        <>
            <ArtisanHeader />
            <SideMenuArtisans />

            <div className={"vertical-overlay"}> </div>

            <div className={"main-content"}>

                <div className={"page-content"}>

                    {useBreadcrumbs("BSE", "Dashboard", "Catalog")}

                    <p className={"alert alert-info"}>Your account <div className={"badge badge-danger"}>TIER</div> allows only one catalog creation</p>

                    {step===1 && (
                        <>
                            <div className={"row mt-4"}>
                                <div className={"col-md-3"}>

                                    <div className={"card card-animate"} onClick={(e)=>{
                                        setStep(2)
                                    }}>

                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/create.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Create Catalog</h5>
                                        </div>

                                    </div>

                                </div>

                                <div className={"col-md-3"}>

                                    <div className={"card card-animate"}>

                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/list.png`}
                                                    alt="" className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Catalog List</h5>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </>
                    )}

                    {step === 2 && (

                        <>

                            <div className={"row mt-4"}>

                            </div>

                        </>
                    )}

                </div>

                <ArtisanFooter />
            </div>



        </>
    )
}

export default ArtisanCatalog