import React from 'react'
import Swal from "sweetalert2";
import axios from "axios";

export const useBaseUrl = () =>{
    return "https://lynnser.pythonanywhere.com"
}

export const useAppName = () =>{

    return "BuilderSE - Helix "
}

export const useGetData = async (url: any) => {

    try{

        const resp = await axios.get(url)

        const {status, message, data} = resp.data

        if(status === "success"){

            return data;

        }else{

            let msg = "Server says: " + message

            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Server says: " + message,
            });

            return

        }

    }catch{

        let msg = "Unable to establish Server - Client connection, are you offline?"

        await Swal.fire({
            icon: "warning",
            title: "Warning",
            text: msg
        });

    }

}

export const usePostData = async (url: any, dat: any) => {

    try{

        const resp = await axios.post(url, dat)

        const {status, message, data} = resp.data

        if(status === "success"){

            return data

        }else{

            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Server says: " + message,
            });

            return

        }

    }catch{

        let msg = "Unable to establish Server - Client connection, are you offline?"

        await Swal.fire({
            icon: "warning",
            title: "Warning",
            text: msg
        });

        return
    }

}

export const useCounties = async () => {

    let url = `${useBaseUrl()}/locations/counties`

    return await useGetData(url)

}

export const useTowns = async(county_code: any) => {
    let url = `${useBaseUrl()}/locations/towns`

    const formData = new FormData()

    formData.append("county_code", county_code)

    return await usePostData(url, formData)
}

export const useBreadcrumbs = (service:any, item: any, subItem: any, BSEName = "BSE- Helix") => {

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{BSEName} - {service}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="javascript: void(0);">{item}</a></li>
                                <li className="breadcrumb-item active">{subItem}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export const useSentenceCase = (word: any) => {

    return word.charAt(0).toUpperCase() + word.slice(1);

}

export const useGreeting = async () => {
    
    

    let greeting
    let type

    try {

        const response = await fetch('http://worldtimeapi.org/api/timezone/Africa/Nairobi')

        const data = await response.json();

        type = data.datetime

        const currentHour: any = new Date(data.datetime).toLocaleString('en-US', { timeZone: 'Africa/Nairobi', hour12: false, hour: 'numeric' });

        if (currentHour >= 0 && currentHour < 12) {
            greeting = "Good Morning"
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = "Good Afternoon"
        } else {
            greeting = "Good Evening"
        }

    } catch (error) {

        console.error('Error fetching time:', error);
    }

    return {greeting, type}

}