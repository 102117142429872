import React from 'react'
import {Link} from "react-router-dom";

const HomePage: React.FC = () => {

    const styling = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/background2.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

    }

    return (
        <>
            <header style={{
                minHeight: '100vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                backgroundColor: "#161617"
            }}>


                <div id="home" className="view jarallax" data-jarallax='{"speed": 0.2}'>
                    <div className="mask rgba-indigo-light">
                        <div className="container h-100 d-flex justify-content-center align-items-center">
                            <div className="row smooth-scroll">
                                <div className="col-md-12 col-xl-12 col-lg-12 text-center">

                                    <div className={"row mt-4"}>

                                        <div className={"col text-center"}>
                                            <h1 style={{color: "white"}}>Builders Search Engine <sup className={"text-danger"} style={{fontSize: "12px"}}>BETA</sup> </h1>
                                        </div>
                                    </div>

                                    <br />
                                    <br />
                                    <br />
                                    <br />

                                    <br />
                                    <br />

                                    <div className={"row mt-4"}>
                                        <div className={"col-md-10"}>

                                            <input className={"form-control"}/>

                                        </div>

                                        <div className={"col-md-2"}>
                                            <button style={{width: "100%"}} className={"btn btn-rounded btn-primary"} disabled={true}>Search</button>
                                        </div>
                                    </div>
                                    <br />
                                    <br />

                                    <br />
                                    <br />

                                    <div className="row mt-4 mb-0">
                                        <div  className="col text-center">

                                            <Link to={"/artisans"} style={{ width: "100%",height: "100%", backgroundRepeat: "no-repeat"}}>

                                                <img src={`${process.env.PUBLIC_URL}/assets/images/artys.png`} />

                                            </Link>

                                            <p style={{
                                                fontSize: "16px",
                                                fontWeight: "Bold",
                                                color: "white"
                                            }}>ARTISANS</p>

                                        </div>

                                        <div className="col">


                                            <Link to={"/pros"} style={{ width: "100%",height: "100%", backgroundRepeat: "no-repeat"}}>

                                                <img src={`${process.env.PUBLIC_URL}/assets/images/office.png`} />

                                            </Link>

                                            <p style={{
                                                fontSize: "16px",
                                                fontWeight: "Bold",
                                                color: "white"
                                            }}>PROFESSIONALS</p>
                                        </div>

                                        <div className="col">
                                            <Link to={"/material"} style={{ width: "100%",height: "100%", backgroundRepeat: "no-repeat"}}>

                                                <img src={`${process.env.PUBLIC_URL}/assets/images/material.png`} />

                                            </Link>

                                            <p style={{
                                                fontSize: "16px",
                                                fontWeight: "Bold",
                                                color: "white"
                                            }}>MATERIAL</p>
                                        </div>

                                        <div className="col">

                                            <Link to={"/houseplans"} style={{ width: "100%",height: "100%", backgroundRepeat: "no-repeat"}}>

                                                <img src={`${process.env.PUBLIC_URL}/assets/images/hpns.png`} />

                                            </Link>

                                            <p style={{
                                                fontSize: "14px",
                                                fontWeight: "Bold",
                                                color: "white"
                                            }}>HOUSE PLANS</p>

                                        </div>



                                        <div className="col">
                                            <Link to={"/"} style={{ width: "100%",height: "100%", backgroundRepeat: "no-repeat"}}>

                                                <img src={`${process.env.PUBLIC_URL}/assets/images/apps.png`} />

                                            </Link>

                                            <p style={{
                                                fontSize: "16px",
                                                fontWeight: "Bold",
                                                color: "white"
                                            }}>BSE APPS</p>
                                        </div>

                                        <div className="col">
                                            <Link to={"/"} style={{ width: "100%",height: "100%", backgroundRepeat: "no-repeat"}}>

                                                <img src={`${process.env.PUBLIC_URL}/assets/images/crypto.png`} />

                                            </Link>

                                            <p style={{
                                                fontSize: "16px",
                                                fontWeight: "Bold",
                                                color: "white"
                                            }}>BSE COIN</p>
                                        </div>

                                        <div className="col">
                                            <Link to={"/"} style={{ width: "100%",height: "100%", backgroundRepeat: "no-repeat"}}>

                                                <img src={`${process.env.PUBLIC_URL}/assets/images/account.png`} />

                                            </Link>

                                            <p style={{
                                                fontSize: "16px",
                                                fontWeight: "Bold",
                                                color: "white"
                                            }}>MY BSE</p>
                                        </div>


                                    </div>

                                    <div style={{height: "25%"}} className={"row mt-4 mb-4"}>
                                        <div className={"col"}>

                                            <h1 style={{color: "#00000000"}}>DNP</h1>

                                            <h1 style={{color: "#00000000"}}>DNP</h1>

                                            <h1 style={{color: "#00000000"}}>DNP</h1>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}


export default HomePage