import React, {useEffect, useState} from "react"
import {useBaseUrl, usePostData} from "../Utils";
import Swal from "sweetalert2";
import localforage from "localforage";

const OneLogin: React.FC = () => {
    useEffect(()=>{
        document.title = "BSE - One Login"
    }, [])



    const [step, setStep] = useState(0)
    const [subStep, setSubStep] = useState(0)
    const [catId, setCatId] = useState<any>(0)
    const [catUrl, setCatUrl] = useState<string>("/onelogin/clients")

    const base_url = useBaseUrl()



    const UpdateCat = (id: any) => {

        setCatId(id)

        if(id === 0){
            setCatUrl("/onelogin/artisans")
            setSubStep(1)
        }else if(id === 1){
            setCatUrl("/onelogin/pros")
            setSubStep(1)
        }else if(id === 2){
            setCatUrl("/onelogin/suppliers")
            setSubStep(1)
        }else if(id === 3){
            setCatUrl("/onelogin/vendors")
            setSubStep(1)
        }else if(id === 4){
            setCatUrl("/onelogin/clients")
            setSubStep(1)
        }else if(id === 5){
            setCatUrl("/onelogin/hpns")
            setSubStep(1)
        }

    }

    const UserLogin = async () => {

        let url = `${base_url} ${catUrl}`

        const formData = new FormData()

        const resp = await usePostData(url, formData)

        if(resp !== undefined){

            await localforage.setItem("fname", resp.fname)
            await localforage.setItem("lname", resp.lname)
            await localforage.setItem("token", resp.token)

            await Swal.fire({
                icon: "success",
                title: `Hi ${resp.fname}!`,
                text: "Proceed to the next step for the final security check"
            })

        }else{
            await Swal.fire({
                icon: "error",
                title: "Illegal Request",
                text: "Illegal text received from this request!"
            })
        }


    }



    return (
        <>
            <div className={"row mt-4"}>

                <div className={"col-md-3"}>

                </div>

                <div className={"col-md-6"}>
                    {step===0 && (
                        <>
                            {subStep===0 && (

                                <>
                                    <div className={"container-fluid"}>

                                        <div className={"row mt-4"}>

                                            <div className={"col-md-3"}>
                                                <div className={"card card-animate"}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/artisan.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Artisans</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col-md-3"}>
                                                <div className={"card card-animate"}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/professionals.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Professionals</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col-md-3"}>
                                                <div className={"card card-animate"}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/vendors.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Vendors</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col-md-3"}>
                                                <div className={"card card-animate"}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/supplier.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Suppliers</h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col-md-3"}>
                                                <div className={"card card-animate"}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/clients.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Clients</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </>

                            )}
                            {subStep===1 && (

                                <>
                                    <div className={"container-fluid"}>

                                        <div className={"row mt-3"}>

                                            <div className={"col-md-4"}>

                                            </div>

                                            <div className={"col-md-4"}>

                                                <div className={"card"}>
                                                    <div className={"card-header text-center"} style={{backgroundColor: "#29b0ea", fontWeight: "Bold" ,color: "white",  border: "None"}}>

                                                    </div>

                                                </div>

                                            </div>

                                            <div className={"col-md-4"}>

                                            </div>

                                        </div>

                                    </div>
                                </>
                            )}

                        </>
                    )}

                </div>

                <div className={"col-md-3"}>

                </div>

            </div>

        </>
    )
}

export default OneLogin