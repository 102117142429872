import React, {useEffect, useState} from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import {Link, useNavigate} from "react-router-dom";
import {useBaseUrl, useCounties, usePostData, useTowns} from "../Utils";


const RegisterArtisans: React.FC = () => {

    const navigate = useNavigate()

    const [changeText, setChangeText] = useState("Basic Information")
    const [step, setStep] = useState(0)
    const [subStep, setSubstep] = useState(0)
    const [contactMethod, setContactMethod] = useState("email")

    useEffect(()=>{
        prefetch_counties()
        prefetch_categories()
        document.title = "Register Artisan"
    }, [subStep])

    const [fname, setFname] =useState("")
    const [mname, setMname] = useState("")
    const [lname, setLname] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordMatch, setPasswordMatch] = useState<boolean>(false)
    const [category, setCategory] = useState("")
    const [countyCode, setCountyCode] = useState("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [counties, setCounties] = useState<any[]>([])
    const [towns, setTowns] = useState<any[]>([])
    const [selectedCounty, setSelectedCounty] = useState("")
    const [selectedTown, setSelectedTown] = useState("")
    const [categories, setCategories] = useState<any[]>([])
    const [artisan, setArtisan] = useState("")
    const [selectedCountyName] = useState<any>("")
    const [selectedTownName] = useState<any>("")

    const base_url = useBaseUrl()


    const prefetch_counties = async () =>{

        let url = base_url + "/locations/counties"

        setIsLoading(true)

        try{

            let formData = new FormData()
            formData.append("county_code", countyCode)

            const response = await axios.post(url, formData).finally(()=>{
                setIsLoading(false)
            })

            const {status, message, data} = response.data

            if(status=="success"){

                setCounties(data)

                return


            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })

                return

            }

        }catch (e) {

            setIsLoading(false)

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Unable to load counties!"
            })

            return
            
        }

    }

    const prefetch_categories = async () =>{

        let url = base_url + "/artisans/categories"

        setIsLoading(true)

        try{

            const response = await axios.get(url).finally(()=>{
                setIsLoading(false)
            })

            const {status, message, data} = response.data

            if(status=="success"){

                setCategories(data)

                return

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })

                return

            }

        }catch (e) {

            setIsLoading(false)

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Server actively refused your connection."
            })

            return

            
        }

    }

    const FetchTowns = async(county_code: any) => {

        let url = base_url + "/locations/towns"

        setIsLoading(true)

        try{

            let formData = new FormData()

            formData.append("county_code", county_code)

            const response = await axios.post(url, formData).finally(()=>{
                setIsLoading(false)
            })

            const {status, message, data } = response.data

            if(status === "success"){

                setTowns(data)

                return

            }else{

                await Swal.fire({
                    icon: "warning",
                    title: "Error",
                    text: message
                })

                return

            }


        }catch (e) {

            setIsLoading(false)

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Failed to fetch towns!"
            })

            return
            
        }

    }


    const ArtisanRegister = async () => {

        setIsLoading(true)

        const url = `${base_url}/artisans/register`

        const formData = new FormData()

        formData.append("fname", fname)
        formData.append("lname", lname)
        formData.append("contactMethod", contactMethod)
        formData.append("town", selectedTown)
        formData.append("password", password)
        formData.append("category", category)

        if (contactMethod === "phone"){

            formData.append("phone", phone)

        }else{

            formData.append("email", email)

        }

        const resp = await usePostData(url, formData)



        if (resp === undefined){

            setIsLoading(false)

            console.log("react tings")

            return

        }

        await Swal.fire({
            icon: "success",
            title: "Registered",
            text: `Welcome to BuilderSE ${fname}. You can now login and complete your profile.`
        })

        setIsLoading(false)

        setEmail("")
        setPhone("")
        setFname("")
        setLname("")
        setSelectedTown("")
        setSelectedCounty("")
        setCategory("")
        setStep(0)

        navigate("/artisans")



    }

    return (
        <>
            <div className={"row mt-4"}>

            </div>
            <div className={"row mt-4"}>

            </div>
            <div className={"row mt-4"}>
                <div className={"col text-center"}>
                    <h2>Register Artisans - {changeText}</h2>
                </div>
            </div>

            <div className={"row mt-4"} style={{fontSize: "14px"}}>
                <div className="col-md-3 col-sm-12">

                </div>

                <div className="col-md-6 col-sm-12">

                    <div className="card">
                        <div className="card-header" style={{backgroundColor: "#29B0EA", color: "white", border: "None"}}>

                            {step === 0 &&(
                                <>
                                    {subStep === 0 && (
                                        <>
                                            Artisan Registration - Getting Ready
                                        </>
                                    )}
                                </>
                            )}
                            {step === 1 && (
                                <>
                                    Artisan's Basic Info
                                </>
                            )}

                            {step===2 && (
                                <>
                                    Hi {fname} {lname}! Choose Category & Location
                                </>
                            )}

                            {step === 3 && (
                                <>
                                    Hi {fname} {lname}. Confirm & Submit
                                </>
                            )}

                        </div>
                        <div className="card-body">
                            {step === 0 && (
                                <>
                                    {subStep === 0 && (
                                        <>
                                            <em>
                                                Welcome Artisan!
                                            </em> <br />

                                            <em>
                                                The registration process is very <strong style={{fontWeight: "Bold"}}>simple</strong> and <strong style={{fontWeight: "Bold"}}>painless</strong>!
                                            </em> <br />

                                            <em>
                                                We'll take just some basic information to get you started which you may be later required to update as you use the platform
                                            </em>

                                            <div className={"row mt-4"}>
                                                <div className={"col"}>

                                                    <button onClick={(e)=>{
                                                        setStep(1)
                                                    }} className={"btn btn-rounded btn-success"} style={{width: "100%"}}>Take me there!</button>

                                                </div>

                                                <div className={"col"}>

                                                    <Link className={"btn btn-rounded btn-primary"} to={"/artisans"} style={{width: "100%"}}>I'll do it later</Link>

                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            {step === 1 && (
                                <>
                                    <em>Lets get to know you!</em> <br />
                                    <div className={"row mt-4"}>
                                        <div className={"col"}>
                                            <label>First Name:</label>

                                            <input className={"form-control"} value={fname} onChange={(e)=>{
                                                setFname(e.currentTarget.value)
                                            }}/>

                                        </div>



                                        <div className={"col"}>
                                            <label>Last Name:</label>

                                            <input className={"form-control"} value={lname} onChange={(e)=>{
                                                setLname(e.currentTarget.value)
                                            }}/>
                                        </div>
                                    </div>

                                    <div className={"row mt-4"}>
                                        <div className={"col"}>
                                            <input type={"radio"} value={"phone"} onChange={(e)=>{
                                                setContactMethod(e.currentTarget.value)
                                            }} checked={contactMethod === "phone"}/> Use Phone Number
                                        </div>

                                        <div className={"col"}>
                                            <input type={"radio"} value={"email"} onChange={(e)=>{
                                                setContactMethod(e.currentTarget.value)
                                            }} checked={contactMethod === "email"}/> Use Email
                                        </div>
                                    </div>

                                    <div className={"row mt-4"}>
                                        <div className={"col"}>
                                            {contactMethod === "phone" && (
                                                <>
                                                    <label>Phone Number: </label>

                                                    <input placeholder={"712-345-678"} value={phone} className={"form-control"} type={"phone"} onChange={(e)=>{
                                                        setPhone(e.currentTarget.value)
                                                    }} />
                                                </>
                                            )}

                                            {contactMethod === "email" && (
                                                <>
                                                    <label>Email: </label>

                                                    <input placeholder={"user@email.com"} value={email} className={"form-control"} type={"email"} onChange={(e)=>{
                                                        setEmail(e.currentTarget.value)
                                                    }} />
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <p className={"mt-4"}><em>Set your password</em> {!passwordMatch && (
                                        <span className={"text-danger"}>Passwords do not match</span>
                                    )}</p>

                                    <div className={"row mt-2"}>
                                        <div className={"col"}>
                                            <label>Password: </label>


                                            <input type={"password"} className={"form-control mt-2"} onChange={(e)=>{
                                                setPassword(e.currentTarget.value)
                                            }} />

                                        </div>

                                        <div className={"col"}>
                                            <label>Confirm Password: </label>

                                            <input type={"password"} className={"form-control mt-2"} onChange={(e)=>{
                                                setPasswordMatch(password===e.currentTarget.value)
                                            }}/>
                                        </div>
                                    </div>

                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <div className={"row mt-4"}>
                                        <div className={"col"}>

                                            <em>Now choose your category and add your location.</em> <br />

                                            <em><span style={{fontWeight: "Bold"}}>Note </span>: You can only have <span style={{fontWeight: "Bold"}}>one</span> category.</em> <br />

                                            <label style={{fontWeight: "Bold"}} className={"mt-2"}>Artisan Type</label>
                                            <select value={category} className={"form-control mt-2"} onChange={(e)=>{
                                                setCategory(e.currentTarget.value)
                                            }}>
                                                <option>Choose Your Category</option>
                                                {Object.values(categories).map((cat: any)=>(
                                                    <option value={cat.id}>{cat.cat}</option>
                                                ))}

                                            </select>

                                            <label style={{fontWeight: "Bold"}} className={"mt-2"}>County</label>
                                            <select value={selectedCounty} className={"form-control mt-2"} onChange={(e)=>{
                                                setSelectedCounty(e.currentTarget.value)

                                                FetchTowns(e.currentTarget.value)

                                            }}>
                                                <option>Choose Your County</option>
                                                {Object.values(counties).map((cty: any)=>(
                                                    <option value={cty.county_code}>{cty.county}</option>
                                                ))}

                                            </select>


                                            {selectedCounty !== "" && selectedCounty !== null && selectedCounty !== undefined && (
                                                <>
                                                    <label style={{fontWeight: "Bold"}} className={"mt-2"}>Town</label>
                                                    <select value={selectedTown} className={"form-control mt-2"} onChange={(e)=>{
                                                        setSelectedTown(e.currentTarget.value)
                                                    }}>
                                                        <option>Your Town / Locality</option>

                                                        {Object.values(towns).map((tnw: any)=>(
                                                            <option value={tnw.id}>{tnw.name}</option>
                                                        ))}

                                                    </select>
                                                </>
                                            )}


                                        </div>
                                    </div>
                                </>
                            )}

                            {step === 3 && (
                                <>
                                    <div style={{fontSize: "15px" }} className={"row mt-2"}>
                                        <div className={"col"}>
                                            <em>Your Name: </em>  &nbsp; &nbsp;
                                        </div>

                                        <div className={"col"}>
                                            <span style={{fontWeight: "Bold"}}>{fname} {lname}</span>
                                        </div>

                                        <div className={"col"}>
                                            <span><button style={{width: "100%"}} className={"btn btn-sm btn-outline-success"} onClick={(e)=>{
                                                setStep(1)
                                            }}>Edit Name</button></span>
                                        </div>
                                    </div>

                                    <div style={{fontSize: "15px" }} className={"row mt-2"}>
                                        <div className={"col"}>
                                            <em>Your Contact Method: </em> <span style={{fontWeight: "Bold"}}>{contactMethod}</span> &nbsp; &nbsp;

                                        </div>

                                        <div className={"col"}>
                                            <span>
                                                {contactMethod==="phone" && (
                                                    <>
                                                        {phone}
                                                    </>
                                                )}
                                                {contactMethod==="email" && (
                                                    <>
                                                        {email}
                                                    </>
                                                )}
                                            </span>
                                        </div>

                                        <div className={"col"}>
                                            <span><button style={{width: "100%"}} className={"btn btn-sm btn-outline-success"} onClick={(e)=>{
                                                setStep(1)
                                            }}>Change This</button></span>
                                        </div>
                                    </div>



                                    <div style={{fontSize: "15px" }} className={"row mt-2"}>
                                        <div className={"col"}>
                                            <em>Your County: </em>  &nbsp; &nbsp;
                                        </div>

                                        <div className={"col"}>
                                            <span style={{fontWeight: "Bold"}}>{"0"+selectedCounty+ " - " + selectedCountyName}</span>
                                        </div>

                                        <div className={"col"}>
                                            <span><button style={{width: "100%"}} className={"btn btn-sm btn-outline-success"} onClick={(e)=>{
                                                setStep(2)
                                            }}>Edit County</button></span>
                                        </div>
                                    </div>

                                    <div style={{fontSize: "15px" }} className={"row mt-2"}>
                                        <div className={"col"}>
                                            <em>Your Town: </em>  &nbsp; &nbsp;
                                        </div>

                                        <div className={"col"}>
                                            <span style={{fontWeight: "Bold"}}>{"0"+selectedTown+ " - " + selectedTownName}</span>
                                        </div>

                                        <div className={"col"}>
                                            <span><button style={{width: "100%"}} className={"btn btn-sm btn-outline-success"} onClick={(e)=>{
                                                setStep(2)
                                            }}>Edit Town</button></span>
                                        </div>
                                    </div>

                                    <div className={"row mt-4"}>
                                        <div className={"col"}>

                                        </div>
                                    </div>

                                </>
                            )}




                        </div>

                        <div className={"card-footer"}>
                            {step===0 &&(
                                <>
                                </>
                            )}

                            {step===1 &&(
                                <div className={"row mt-2 mb-2"}>

                                    <div className={"col"}>
                                        <button disabled={!passwordMatch} className={"btn btn-primary"} onClick={(e)=>{
                                            setChangeText(fname+"'s Category")
                                            setStep(2)
                                        }} style={{width: "100%"}}>Next Step</button>
                                    </div>

                                    <div className={"col"}>
                                        <button className={"btn btn-primary"} style={{width: "100%"}} onClick={(e)=>{
                                            setEmail("")
                                            setPhone("")
                                            setFname("")
                                            setLname("")
                                        }} >Reset Form</button>
                                    </div>

                                    <div className={"col"}>
                                        <Link to={"/artisans"} className={"btn btn-primary"} style={{width: "100%"}}>Cancel Process</Link>
                                    </div>

                                </div>
                            )}

                            {step === 2 && (
                                <div className={"row mt-2 mb-2"}>

                                    <div className={"col"}>
                                        <button onClick={(e)=>{
                                            setChangeText(fname+"'s Basic Info")
                                            setStep(step-1)
                                        }} className={"btn btn-primary"} style={{width: "100%"}}>Previous Step</button>
                                    </div>

                                    <div className={"col"}>
                                        <button className={"btn btn-primary"} style={{width: "100%"}} onClick={(e)=>{
                                            setEmail("")
                                            setPhone("")
                                            setFname("")
                                            setLname("")
                                        }} >Reset Form</button>
                                    </div>

                                    <div className={"col"}>
                                        <button onClick={(e)=>{
                                            setChangeText("Confirm & Submit")
                                            setStep(3)
                                        }}  className={"btn btn-primary"} style={{width: "100%"}}>Next Step</button>
                                    </div>

                                </div>
                            )}

                            {step === 3 && (
                                <div className={"row mt-2 mb-2"}>

                                    <div className={"col"}>
                                        <button onClick={(e)=>{
                                            setChangeText(fname+"'s Category")
                                            setStep(step-1)
                                        }} className={"btn btn-primary"} style={{width: "100%"}}>Previous Step</button>
                                    </div>

                                    <div className={"col"}>
                                        <button className={"btn btn-success"} disabled={isLoading} style={{width: "100%"}} onClick={(e)=>{

                                            ArtisanRegister()

                                        }} >Confirm & Register</button>
                                    </div>

                                    <div className={"col"}>
                                        <button onClick={(e)=>{
                                            setEmail("")
                                            setPhone("")
                                            setFname("")
                                            setLname("")
                                            setSelectedTown("")
                                            setSelectedCounty("")
                                            setCategory("")
                                            setStep(0)
                                        }}  className={"btn btn-primary"} style={{width: "100%"}}>Cancel Registration</button>
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-sm-12">

                </div>

            </div>

        </>
    )
}

export default RegisterArtisans