import React, {useState} from 'react'
import {Swiper, SwiperSlide} from "swiper/react"

const PlanSearchResults: React.FC = () => {

    const [collapseState, setCollapseState] = useState<boolean>(true)
    const [collapse2, setCollapse2] = useState<boolean>(true)
    const [collapseT, setCollapseT] = useState<boolean>(true)

    return(
        <>
            <div className={"row mt-4"}>

                <div className={"col-md-12"}>

                    <div className={"container-fluid"}>

                        <div className={"row mt-4"}>

                            <div className={"col-md-1"}>

                            </div>

                            <div className={"col-md-11"}>

                                <div className={"card"} style={{backgroundColor: "#6599CB"}}>

                                    <p style={{color: "#6599CB"}}> Plan No: 334455 </p>

                                    <p> </p>

                                    <p> </p>

                                </div>

                                <div className={"row mt-4 text-center"}>
                                    <div className={"col"}>
                                        <i className="bx bx-bed" style={{fontSize: "40px", color: "#6088D9"}}> </i>
                                        <h5 style={{color: "#6088D9"}}> 4 Beds</h5>
                                    </div>

                                    <div className={"col"}>
                                        <i className="bx bx-bath" style={{fontSize: "40px", color: "#6088D9"}}> </i>
                                        <h5 style={{color: "#6088D9"}}>4 Baths</h5>
                                    </div>

                                    <div className={"col"}>
                                        <i className="bx bx-math" style={{fontSize: "40px", color: "#6088D9"}}> </i>
                                        <h5 style={{color: "#6088D9"}}>400 m<sup>2</sup></h5>
                                    </div>

                                    <div className={"col"}>
                                        <i className="bx bx-line-chart" style={{fontSize: "40px", color: "#6088D9"}}> </i>
                                        <h5 style={{color: "#6088D9"}}>Width -  4 m</h5>
                                    </div>

                                    <div className={"col"}>

                                        <i className="bx bx-ruler" style={{fontSize: "40px", color: "#6088D9"}}> </i>
                                        <h5 style={{color: "#6088D9"}}>Length -  4 m</h5>
                                    </div>

                                    <div className={"col"}>

                                        <i className="bx bx-building-house" style={{fontSize: "40px", color: "#6088D9"}}> </i>
                                        <h5 style={{color: "#6088D9"}}>4 Storeys</h5>
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col-md-6"}>

                                        <div className={"container-fluid"}>

                                            <img src={`${process.env.PUBLIC_URL}/assets/images/plan.jpg`} style={{borderRadius: "3%", width: "100%"}}/>

                                        </div>

                                    </div>

                                    <div className={"col-md-1"}>

                                        <div className={"container-fluid"}>

                                            <div className={"row"}>

                                                <div className={"col"}>

                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/plan.jpg`} style={{borderRadius: "3%", width: "100%"}}/>

                                                </div>

                                            </div>

                                            <div className={"row mt-3"}>

                                                <div className={"col"}>

                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/plan.jpg`} style={{borderRadius: "3%", width: "100%"}}/>

                                                </div>

                                            </div>

                                            <div className={"row mt-3"}>

                                                <div className={"col"}>

                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/plan.jpg`} style={{borderRadius: "3%", width: "100%"}}/>

                                                </div>

                                            </div>

                                            <div className={"row mt-3"}>

                                                <div className={"col"}>

                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/plan.jpg`} style={{borderRadius: "3%", width: "100%"}}/>

                                                </div>

                                            </div>

                                            <div className={"row mt-3"}>

                                                <div className={"col"}>

                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/plan.jpg`} style={{borderRadius: "3%", width: "100%"}}/>

                                                </div>

                                            </div>

                                            <div className={"row mt-3"}>

                                                <div className={"col"}>

                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/plan.jpg`} style={{borderRadius: "3%", width: "100%"}}/>

                                                </div>

                                            </div>

                                            <div className={"row mt-3"}>

                                                <div className={"col"}>

                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/plan.jpg`} style={{borderRadius: "3%", width: "100%"}}/>

                                                </div>

                                            </div>



                                        </div>

                                    </div>

                                    <div className={"col-md-5"}>

                                        <div className={"row"}>

                                            <div className={"col"}>

                                                <div className={"btn btn-primary"} style={{width: "100%"}} >

                                                   <i className={"bx bx-cart"}> </i> Buy Plan

                                                </div>

                                            </div>

                                        </div>

                                        <div className={"row mt-3"}>
                                            <div className={"col"}>

                                                <p style={{fontSize: "20px" }}>  <span style={{fontWeight : "Bold"}}> Price :</span> Kes. 15,000 </p>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>
                                            <div className={"col"}>

                                                <div className={"card"}>

                                                    <div className={"card-header"} onClick={(e)=>{
                                                        setCollapseState(!collapseState)
                                                    }}>

                                                        {collapseState && (<i className="bx bx-plus"> </i>)} {!collapseState && (<i className="bx bx-minus"> </i>)} <span style={{fontWeight: "bold"}}>Choose Drawings</span>

                                                    </div>

                                                    {collapseState ?  null : (

                                                        <>
                                                            <div className={"card-body"}>

                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <input type={"checkbox"} />  &nbsp; Structural

                                                                    </div>
                                                                </div>

                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <input type={"checkbox"} />  &nbsp; Architectural

                                                                    </div>
                                                                </div>

                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <input type={"checkbox"} />  &nbsp; Work Schedule

                                                                    </div>
                                                                </div>

                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <input type={"checkbox"} />  &nbsp; Material Schedule

                                                                    </div>
                                                                </div>

                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <input type={"checkbox"} />  &nbsp; Civil Works

                                                                    </div>
                                                                </div>

                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <input type={"checkbox"} />  &nbsp; M.E.P

                                                                    </div>
                                                                </div>

                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <input type={"checkbox"} />  &nbsp; B.O.Q

                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </>

                                                    )}
                                                </div>


                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>
                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <div className={"card-header"} onClick={(e)=>{
                                                        setCollapse2(!collapse2)
                                                    }}>

                                                        {collapse2 && (<i className="bx bx-plus"> </i>)} {!collapse2 && (<i className="bx bx-minus"> </i>)} <span style={{fontWeight: "bold"}}>Specifications</span>

                                                    </div>

                                                    {collapse2 ? null: (
                                                        <div className={"card-body"}>
                                                            <table className={"table table-striped my-table"}>
                                                                <tbody>
                                                                <tr>
                                                                    <td>
                                                                        Total Living Area:
                                                                    </td>

                                                                    <td>
                                                                        200 m<sup>2</sup>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Main Living Area:
                                                                    </td>

                                                                    <td>
                                                                        200 m<sup>2</sup>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Foundation Type:
                                                                    </td>

                                                                    <td>
                                                                        Suspended
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Stories:
                                                                    </td>

                                                                    <td>
                                                                        2
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Living Rooms:
                                                                    </td>

                                                                    <td>
                                                                        1
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Bed Rooms:
                                                                    </td>

                                                                    <td>
                                                                        Suspended
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        Roof Type:
                                                                    </td>

                                                                    <td>
                                                                        Suspended
                                                                    </td>
                                                                </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )}

                                                </div>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>
                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <div className={"card-header"} onClick={(e)=>{
                                                        setCollapseT(!collapseT)
                                                    }}>

                                                        {collapseT && (<i className="bx bx-plus"> </i>)} {!collapseT && (<i className="bx bx-minus"> </i>)} <span style={{fontWeight: "bold"}}>Technical</span>

                                                    </div>

                                                    {collapseT ? null: (
                                                        <div className={"card-body"}>
                                                            <div className={"container-fluid"}>

                                                                <label>Select Town:  </label>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className={"col-md-1"}>

                                
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default PlanSearchResults