import React, {useEffect, useState} from "react";
import ProHeader from "./ProHeader";
import ArtisanFooter from "../../artisans/dashboard/ArtisanFooter";
import {useBreadcrumbs} from "../../Utils";
import SideMenuArtisans from "./SideMenu";
import { Link } from "react-router-dom";

const ProProjects: React.FC = () => {

    const [step, setStep] = useState(1)

    useEffect(()=>{
        document.title = "BSE - Project Manager"
    }, [])

    return (
        <>
            <ProHeader />
            <SideMenuArtisans />
            <div className={"vertical-overlay"} />

            <div className={"main-content"}>
                <div className={"page-content"}>

                    {useBreadcrumbs("BSE Project Manager", "Dashboard", "Project Manager")}

                    <div className={"container-fluid"}>

                        <div className={"row mt-4"}>
                            <div className={"col text-center"}>

                                <h3>Welcome User</h3>

                            </div>
                        </div>

                        <div className={"row mt-3"}>
                            <div className={"col text-center"}>
                                <h6>Choose an app to get started!</h6>
                            </div>
                        </div>

                        <div className={"row mt-4"}>


                            <div className={"col-md-2"}>

                                <div className={"card card-animate"} onClick={(e)=>{
                                    setStep(2)
                                }}>

                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/create.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Create Project</h5>
                                    </div>

                                </div>



                            </div>

                            <div className={"col-md-2"}>

                                <div className={"card card-animate"} onClick={(e)=>{
                                    setStep(3)
                                }}>

                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/list.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">My Projects</h5>
                                    </div>

                                </div>

                            </div>

                            <div className={"col-md-2"}>

                                <div className={"card card-animate"} onClick={(e)=>{
                                    setStep(3)
                                }}>

                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/tasks.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">My Tasks</h5>
                                    </div>

                                </div>

                            </div>

                            <div className={"col-md-2"}>

                                <div className={"card card-animate"} onClick={(e)=>{
                                    setStep(3)
                                }}>

                                    <Link to={"/pros/dashboard/teams"} className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/teams.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">My Teams</h5>
                                    </Link>

                                </div>

                            </div>

                            <div className={"col-md-2"}>

                                <div className={"card card-animate"} onClick={(e)=>{
                                    setStep(3)
                                }}>

                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/requests.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">My Requests</h5>
                                    </div>

                                </div>

                            </div>

                            <div className={"col-md-2"}>

                                <div className={"card card-animate"} onClick={(e)=>{
                                    setStep(3)
                                }}>

                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/piecharts.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">My Charts</h5>
                                    </div>

                                </div>

                            </div>


                            <div className={"col-md-2"}>

                                <div className={"card card-animate"} onClick={(e)=>{
                                    setStep(3)
                                }}>

                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/communication.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Comms</h5>
                                    </div>

                                </div>

                            </div>

                            <div className={"col-md-2"}>

                                <div className={"card card-animate"} onClick={(e)=>{
                                    setStep(3)
                                }}>

                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/resource.png`}
                                                alt="" className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Resources</h5>
                                    </div>

                                </div>

                            </div>



                        </div>

                    </div>

                </div>
                <ArtisanFooter />
            </div>
        </>
    )
}

export default ProProjects