import React, {useEffect, useReducer} from 'react'
import ArtisanHeader from "./ArtisanHeader";
import SideMenuArtisans from "./SideMenu";
import ArtisanFooter from "./ArtisanFooter";

const ArtisanMessaging: React.FC= () => {
    const initialState = {
        pageTitle: "BuilderSE Communications Center",
        step: 0,
        subStep: 0,
        user: "Guest User"
    }

    const reducer = (state: any, action: any) => {

        switch (action.type){

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_PAGE_HEADING":
                return {...state, pageTitle: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            case "SET_USER":
                return {...state, user: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(()=>{
        document.title= state.pageTitle
    }, [])

    return (
        <>

            <ArtisanHeader />
            <SideMenuArtisans />
            <div className={"vertical-overlay"} />

            <div className={"main-content"}>
                <div className={"page-content"}>


                    <div className={"row mt-4"}>

                        <div className={"col"}>
                            {state.step === 0 && (
                                <>
                                    <div className={"container-fluid"}>
                                        <div className={"row mt-4"}>
                                            <div className={"col text-center"}>

                                                <h3>Welcome {state.user}</h3>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>
                                            <div className={"col text-center"}>
                                                <h6>{state.pageTitle}</h6>
                                            </div>
                                        </div>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-2"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_STEP", payload: 1})
                                                }}>

                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/notifications.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Notifications</h5>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className={"col-md-2"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_STEP", payload: 2})
                                                }}>

                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/chat.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Chat Center</h5>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className={"col-md-2"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_STEP", payload: 3})
                                                }}>

                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/conferencing.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">E-Conferencing</h5>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className={"col-md-2"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_STEP", payload: 4})
                                                }}>

                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/schedules.png`}
                                                                alt="" className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Schedules</h5>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                    </div>

                </div>

                <ArtisanFooter />

            </div>

        </>
    )
}

export default ArtisanMessaging