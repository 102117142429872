import React, {useEffect, useState, useReducer} from 'react'
import ArtisanHeader from "./ArtisanHeader";
import SideMenuArtisans from "./SideMenu";
import {useBreadcrumbs} from "../../Utils";
import {Link} from "react-router-dom";
import ArtisanFooter from "./ArtisanFooter";



const ArtisanTeams: React.FC = () => {

    const initialState = {
        step: 0,
        pageHeading: "BuilderSE Teams App",
        user: "User",
        teamLimit: 5
    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case 'SET_STEP':
                return {...state, step: action.payload}

            case 'SET_PAGE_HEADING':
                return {...state, pageHeading: action.payload}

            case 'SET_USER':
                return {...state, user: action.payload}

            case 'SET_TEAM_LIMIT':
                return {...state, teamLimit: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(()=>{
        document.title = state.pageHeading
    }, [])

    return (
        <>
            <ArtisanHeader />
            <SideMenuArtisans />
            <div className={"vertical-overlay"} />

            <div className={"main-content"}>
                <div className={"page-content"}>

                    {useBreadcrumbs("BSE Teams Manager", "Dashboard", "Teams Manager")}

                    {state.step === 0 && (
                        <>
                            <div className={"container-fluid"}>
                                <div className={"row mt-4"}>
                                    <div className={"col text-center"}>

                                        <h3>Welcome {state.user}</h3>

                                    </div>
                                </div>

                                <div className={"row mt-3"}>
                                    <div className={"col text-center"}>
                                        <h6>Manage Your Teams Here! - Your Limit is {state.teamLimit} Teams</h6>
                                    </div>
                                </div>
                                <div className={"row mt-4"}>
                                    <div className={"col-md-2"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_STEP", payload: 1})
                                        }}>

                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/create.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Create Team</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"col-md-2"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_STEP", payload: 2})
                                        }}>

                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/teamlink.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Join Team</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"col-md-2"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_STEP", payload: 3})
                                        }}>

                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/list.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">My Teams</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"col-md-2"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_STEP", payload: 4})
                                        }}>

                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/history.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Team History</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"col-md-2"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_STEP", payload: 5})
                                        }}>

                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/feedback.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Team Feedback</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"col-md-2"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_STEP", payload: 6})
                                        }}>

                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/history.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Team History</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"col-md-2"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_STEP", payload: 7})
                                        }}>

                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/analytics.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Team Analytics</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"col-md-2"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_STEP", payload: 8})
                                        }}>

                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/revenue.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Team Revenue</h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}



                </div>
                <ArtisanFooter />
            </div>
        </>
    )
}

export default ArtisanTeams