import React from 'react';
import { Link } from 'react-router-dom'
import ArtisanHeader from "./ArtisanHeader";
import ArtisanSideMenu from "./ArtisanSideMenu";

const SideMenuArtisans: React.FC = () => {


    return (
        <>


            <div className="app-menu navbar-menu">

                <div className="navbar-brand-box">

                    <a href={"/institution/home"} className="logo logo-dark">
                    <span className="logo-sm">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.png`} alt="" height="48" />
                    </span>
                        <span className="logo-lg">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-dark.png`} alt="" height="17" />
                    </span>
                    </a>

                    <a href={"/institution/home"} className="logo logo-light">
                    <span className="logo-sm">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.png`} alt="" height="22" />
                    </span>
                        <span className="logo-lg">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-light.png`} alt="" height="44" />
                    </span>
                    </a>
                    <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                        <i className="ri-record-circle-line"> </i>
                    </button>
                </div>

                <div id="scrollbar" data-simplebar="init" className="h-100 simplebar-scrollable-y">
                    <div className="container-fluid">

                        <div id="two-column-menu">
                        </div>
                        <ul className="navbar-nav" id="navbar-nav">
                            <li className="menu-title"> <span data-key="t-menu">Menu</span> </li>

                            <li className={"nav-item"}>

                                <Link to={"/artisans/dashboard/"} className={"nav-link"}>
                                    <i className="bx bxs-dashboard"> </i> <span data-key="t-apps">Dashboard</span>
                                </Link>

                            </li>

                            <li className={"nav-item"}>
                                <a className={"nav-link menu-link"} href={"#apps"} data-bs-toggle={"collapse"} role={"button"} aria-expanded={false} aria-controls={"apps"}>

                                    <i className="bx bx-layer"> </i> <span data-key="t-apps">BSE Apps</span>

                                </a>

                                <div className={"collapse menu-dropdown mega-dropdown-menu"} id={"apps"}>

                                    <ul className={"nav nav-sm flex-column"}>

                                        <li className={"nav-item"}>
                                            <Link to={"/artisans/dashboard/calendar"} className={"nav-link"}>
                                                Calendar
                                            </Link>
                                        </li>

                                        <li className={"nav-item"}>
                                            <Link to={"/artisans/dashboard/messaging"} className={"nav-link"}>
                                                Messaging Center
                                            </Link>
                                        </li>

                                        <li className={"nav-item"}>
                                            <Link to={"/artisans/dashboard/documents"} className={"nav-link"}>
                                                Document Manager
                                            </Link>
                                        </li>

                                        <li className={"nav-item"}>
                                            <Link to={"/artisans/dashboard/wallet"} className={"nav-link"}>
                                                Wallet
                                            </Link>
                                        </li>

                                        <li className={"nav-item"}>
                                            <Link to={"/artisans/dashboard/files"} className={"nav-link"}>
                                                File Manager
                                            </Link>
                                        </li>

                                    </ul>

                                </div>

                            </li>


                            <li className="menu-title"> <i className="ri-more-fill"> </i> <span data-key="t-components">Core</span>
                            </li>

                            <li className={"nav-item"}>
                                <a className={"nav-link menu-link"} href={"#projectManagement"} data-bs-toggle={"collapse"} role={"button"} aria-expanded={false} aria-controls={"projectManagement"}>

                                    <i className="bx bx-briefcase"> </i> <span data-key={"t-projectManagement"}> Project Management </span>

                                </a>

                                <div className={"collapse menu-dropdown mega-dropdown-menu"} id={"projectManagement"}>

                                    <ul className={"nav nav-sm flex-column"}>

                                        <li className={"nav-item"}>
                                            <Link to={"/artisans/dashboard/projects"} className={"nav-link"}> Launch App </Link>
                                        </li>

                                    </ul>

                                </div>

                            </li>

                            <li className={"nav-item"}>

                                <a className={"nav-link menu-link"} href={"#jobs"} data-bs-toggle={"collapse"} role={"button"} aria-expanded={false} aria-controls={"jobs"}>

                                    <i className="bx bx-file"> </i> <span data-key={"t-jobs"}> Jobs </span>
                                </a>

                                <div className={"collapse menu-dropdown mega-dropdown-menu"} id={"jobs"}>

                                </div>

                            </li>


                            <li className="menu-title"><i className="ri-more-fill"> </i> <span  data-key="t-pages">Account</span></li>

                            <li className={"nav-item"}>

                                <a className={"nav-link menu-link"} href={"#profile"} data-bs-toggle={"collapse"} role={"button"} aria-expanded={false} aria-controls={"profile"}>

                                    <i className="bx bx-user"> </i> <span data-key={"t-profile"}> My profile </span>
                                </a>

                                <div className={"collapse menu-dropdown mega-dropdown-menu"} id={"profile"}>

                                    <ul className={"nav nav-sm flex-column"}>

                                        <li className={"nav-item"}>
                                            <Link to={"/artisans/dashboard/profile"} className={"nav-link"}> Launch App </Link>
                                        </li>

                                    </ul>

                                </div>

                            </li>



                        </ul>
                    </div>
                </div>

                <div className="sidebar-background"> </div>
            </div>

        </>
    )
}

export default SideMenuArtisans
